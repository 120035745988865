import { UserApi } from 'modules/api-client';
import { UserAction } from './actions';

import i18next from '../../i18n';

export type UserStore = {
  token: UserApi['token'] | null;
  email: UserApi['email'] | null;
  admin: UserApi['admin'];
  organizationId: UserApi['organizationId'] | null;
  organizationName: UserApi['organizationName'] | null;
  organizationPlan: UserApi['organizationPlan'] | null;
  adminOfOrganization: UserApi['adminOfOrganization'];
  workspaceId: UserApi['workspaceId'] | null;
  group: UserApi['group'] | null;
  firstName: UserApi['firstName'] | null;
  lastName: UserApi['lastName'] | null;
  id: UserApi['id'] | null;
  integrationCount: UserApi['integrationCount'] | null;
  assetCount: UserApi['assetCount'] | null;
  language: string | null;
  jobTitle: string | null;
  signUpInProgress: boolean;
};

const initialState: UserStore = {
  admin: false,
  adminOfOrganization: false,
  email: null,
  firstName: null,
  group: null,
  id: null,
  lastName: null,
  organizationId: null,
  organizationName: null,
  organizationPlan: null,
  token: null,
  workspaceId: null,
  integrationCount: null,
  assetCount: null,
  language: null,
  jobTitle: null,
  signUpInProgress: false,
};

export const userReducer = (
  state = initialState,
  action: UserAction,
): UserStore => {
  switch (action.type) {
    case 'USER_LOGIN_SUCCEEDED':
      return {
        ...state,
        admin: action.admin,
        adminOfOrganization: action.adminOfOrganization,
        email: action.email,
        firstName: action.firstName,
        group: action.group,
        id: action.id,
        lastName: action.lastName,
        organizationId: action.organizationId,
        organizationName: action.organizationName,
        organizationPlan: action.organizationPlan,
        token: action.token,
        workspaceId: action.workspaceId,
        integrationCount: action.integrationCount,
        assetCount: action.assetCount,
        language: i18next.language,
        jobTitle: action.jobTitle,
        signUpInProgress: action.signUpInProgress,
      };

    case 'CHOOSE_LANGUAGE':
      return {
        ...state,
        language: action.language,
      };

    case 'USER_DISCONNECTED':
      return { ...initialState };

    case 'USER_SWITCH_TOKEN':
      return {
        ...state,
        admin: action.data.admin,
        adminOfOrganization: action.data.adminOfOrganization,
        token: action.data.token,
        workspaceId: action.data.workspaceId,
        group: action.data.group,
      };

    case 'USER_UPDATE_ORG_DATA':
      return {
        ...state,
        assetCount: action.data.assetCount,
        integrationCount: action.data.integrationCount,
      };
    default:
      return state;
  }
};
