export type UnarchiveIconProps = {
  size?: number;
};

export const UnarchiveIcon = ({ size = 16 }: UnarchiveIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox={`0 0 ${size} ${size}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.6053 1.36014L1.11723 3.87437C1.05248 3.88854 1.01149 3.95251 1.02566 4.01725L1.57725 6.5376L13.2998 3.97206L12.7482 1.45171C12.734 1.38697 12.6701 1.34597 12.6053 1.36014Z"
      fill="white"
      stroke="#2D2F30"
    />
    <path d="M2.6001 14V6.39996H13.4001V14H2.6001Z" stroke="#2D2F30" />
    <rect
      x="4.6999"
      y="8.09999"
      width="6.6"
      height="0.6"
      rx="0.3"
      stroke="#2D2F30"
      strokeWidth="0.6"
    />
  </svg>
);
