import colors from 'constants/colors';
import {
  ChevronUpAndDownIcon,
  SettingsIcon2,
  Tooltip,
} from 'modules/common-ui';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import OutsideClickHandler from 'react-outside-click-handler';
import { NavLink } from 'react-router-dom';
import {
  ChevronContainer,
  WorkspacePickerButtonContainer,
  WorkspacePickerButtonContent,
  WorkspacePickerButtonRight,
  WorkspaceTitle,
} from './WorkspacePicker.css';
import { WorkspaceSwitcher } from './components/WorkspaceSwitcher/WorkspaceSwitcher';

type WorkspacePickerProps = {
  loading: boolean;
  currentWorkspace: any;
  workspaces: any[];
  onChange: (workspaceId: number) => void;
  flat?: boolean;
};

export const WorkspacePicker = ({
  loading,
  currentWorkspace,
  workspaces,
  onChange,
  flat,
}: WorkspacePickerProps) => {
  const [display, setDisplay] = useState<boolean>(false);
  const [t] = useTranslation('administration');

  return (
    <div>
      <OutsideClickHandler onOutsideClick={() => setDisplay(false)}>
        <WorkspacePickerButtonContainer>
          <WorkspacePickerButtonContent
            clickable={!flat}
            onClick={() => {
              if (!flat) {
                setDisplay(!display);
              }
            }}
          >
            {!flat && (
              <ChevronContainer>
                <ChevronUpAndDownIcon color={colors.gray850} />
              </ChevronContainer>
            )}

            <WorkspaceTitle>
              {!loading && currentWorkspace && (
                <div id="current-workspace-title">{currentWorkspace.name}</div>
              )}
            </WorkspaceTitle>
          </WorkspacePickerButtonContent>
          <WorkspacePickerButtonRight onClick={() => setDisplay(false)}>
            <NavLink
              to="/settings/general"
              data-testid="navigation-settings"
              data-tip={t(
                'administration.workspacePicker.navWorkspaceSettings',
              )}
              data-for="tooltip-navigation-settings"
            >
              <SettingsIcon2 color={colors.gray850} />
              <Tooltip id="tooltip-navigation-settings" place="right" />
            </NavLink>
          </WorkspacePickerButtonRight>
        </WorkspacePickerButtonContainer>

        {display && currentWorkspace && (
          <WorkspaceSwitcher
            currentWorkspace={currentWorkspace}
            workspaces={workspaces}
            onChange={onChange}
            onClose={() => {
              setDisplay(false);
            }}
          />
        )}
      </OutsideClickHandler>
    </div>
  );
};
