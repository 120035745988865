import moment from 'moment';

import { ASSET_CHRONOLOGY, AssetChronologyAction } from '../actions';
import {
  AssetZoneDateType,
  CategoryType,
  EnrichedAssetZoneDateType,
} from '../types';

export type AssetChronologyStateType = {
  allAddressCategories: CategoryType[];
  assetZoneDate: AssetZoneDateType[];
  enrichedAssetZoneDate: EnrichedAssetZoneDateType[];
  filters: { [key: string]: any };
  visibleColumns: string[];
};

const initialState: AssetChronologyStateType = {
  allAddressCategories: [],
  assetZoneDate: [],
  enrichedAssetZoneDate: [],
  filters: {},
  visibleColumns: [],
};

export default function assetChronologyReducer(
  state = initialState,
  action: AssetChronologyAction,
) {
  switch (action.type) {
    case ASSET_CHRONOLOGY.RECEIVED_CHRONOLOGY_DATA: {
      const { workspace } = action.data.viewer;
      const { assetZoneDate, allAddressCategories } = workspace;

      const enrichedAssetZoneDate: EnrichedAssetZoneDateType[] =
        assetZoneDate.map((assetZoneDate) => {
          let timeSpent;
          const { dateStart, dateEnd } = assetZoneDate;
          if (dateEnd && dateStart) {
            timeSpent = Math.round(
              moment.duration(moment(dateEnd).diff(moment(dateStart))).asDays(),
            );
          } else if (!dateEnd && dateStart) {
            const now = moment();
            timeSpent = Math.round(
              moment.duration(moment(now).diff(moment(dateStart))).asDays(),
            );
          }

          return { ...assetZoneDate, timeSpent };
        });

      return {
        ...state,
        allAddressCategories,
        assetZoneDate,
        enrichedAssetZoneDate,
      };
    }

    case ASSET_CHRONOLOGY.SET_VISIBLE_COLUMNS: {
      const { columns } = action;

      return { ...state, visibleColumns: columns };
    }

    case ASSET_CHRONOLOGY.FILTER: {
      const { filters } = action;

      return { ...state, filters };
    }

    default:
      return state;
  }
}
