import moment, { Moment } from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { DayPickerRangeController, FocusedInputShape } from 'react-dates';
import { useTranslation } from 'react-i18next';

import {
  ArrowRightIcon,
  ChevronDown,
  Drawer,
  DRAWER_TRANSITION_MS,
} from 'modules/common-ui';

import {
  DateRangePeriodType,
  PeriodKeys,
  PeriodOptions,
} from './DateRangePeriods';
import {
  Action,
  CalendarActions,
  CalendarContainer,
  Container,
  DateLabel,
  DatePickerInput,
  Left,
  NavNext,
  NavPrev,
  PeriodOption,
  Right,
  StyledWrapper,
  Tip,
} from './DateRangePickerMobile.css';

export type DateRangePickerMobileProps = {
  initialPeriod?: DateRangePeriodType;
  onDatesSelect: (p: DateRangePeriodType) => void;
  periodOptions?: PeriodKeys[];
  withMarginTop?: boolean;
  minDate?: Moment;
  maxDate?: Moment;
  optionalEndDate?: boolean;
  useRawPeriodLabelTip?: boolean;
  hidePeriodLabelTip?: boolean;
  forwardRef?: React.RefObject<HTMLDivElement>;
};

// eslint-disable-next-line max-statements
export const DateRangePickerMobile = ({
  initialPeriod = PeriodOptions.last7d,
  onDatesSelect,
  periodOptions = [
    'last7d',
    'last15d',
    'lastWeek',
    'lastMonth',
    'startOfMonth',
    'startOfWeek',
    'startOfYear',
    'custom',
  ],
  withMarginTop = false,
  minDate,
  maxDate,
  optionalEndDate = false,
  useRawPeriodLabelTip = false,
  hidePeriodLabelTip = false,
  forwardRef,
}: DateRangePickerMobileProps) => {
  const [t] = useTranslation('commonUi');

  const [showPicker, setShowPicker] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);

  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(
    'startDate',
  );
  const [startDate, setStartDate] = useState<Moment | null>(
    initialPeriod?.since,
  );
  const [endDate, setEndDate] = useState<Moment | null>(initialPeriod?.until);
  const [selectedPeriod, setSelectedPeriod] =
    useState<DateRangePeriodType | null>(initialPeriod);

  const emptyPresetPeriods = periodOptions.length === 0;

  const checkDateIsOutsideRange = useCallback(
    (day: any): boolean => {
      const isBeforeMinDate = !!minDate && moment(day).isBefore(minDate);
      const isAfterMaxDate = !!maxDate && moment(day).isAfter(maxDate);
      return isBeforeMinDate || isAfterMaxDate;
    },
    [maxDate, minDate],
  );

  let periodTip = '';
  if (selectedPeriod && selectedPeriod.value) {
    if (selectedPeriod.value === PeriodOptions.custom.value) {
      periodTip = t(PeriodOptions.custom.label);
    } else {
      periodTip = useRawPeriodLabelTip
        ? selectedPeriod.label
        : t(selectedPeriod.label);
    }
  }

  const onDatesChange = useCallback(
    ({
      startDate,
      endDate,
    }: {
      startDate: Moment | null;
      endDate: Moment | null;
    }) => {
      // toggle focus when dates change, to allow user to change startDate
      setFocusedInput((focusedInput) => {
        return focusedInput === 'endDate' && !endDate ? 'endDate' : 'startDate';
      });

      setSelectedPeriod(PeriodOptions.custom);
      setStartDate(startDate);
      setEndDate(endDate);
    },
    [],
  );

  const onFocusChange = (pfocusedInput: FocusedInputShape | null) => {
    if (focusedInput !== pfocusedInput) {
      setFocusedInput(pfocusedInput);
    }
  };

  const openDatePicker = () => {
    if (showPicker === false) {
      setShowPicker(true);
    }
  };

  const onPeriodSelect = useCallback(
    (period: DateRangePeriodType | null) => {
      setSelectedPeriod(period);

      if (period && period.value === PeriodOptions.custom.value) {
        setShowCalendar(true);
      }

      if (period && (period.since || period.until)) {
        setStartDate(period.since);
        setEndDate(period.until);

        // if not custom period, apply it
        if (period.value !== PeriodOptions.custom.value) {
          setShowPicker(false);

          // wait until drawer to close smoothly
          setTimeout(() => {
            onDatesSelect({
              label: period.label,
              value: period.value,
              since: period.since,
              until: period.until,
            });
          }, DRAWER_TRANSITION_MS);
        }
      }
    },
    [onDatesSelect],
  );

  const onClose = useCallback(() => {
    setShowPicker(false);

    if (startDate && (endDate || optionalEndDate)) {
      let label = PeriodOptions.custom.label;
      let value = PeriodOptions.custom.value;

      if (selectedPeriod) {
        label = selectedPeriod.label;
        value = selectedPeriod.value;
      }

      // wait until drawer to close smoothly
      setTimeout(() => {
        onDatesSelect({
          label,
          value,
          since: startDate,
          until: endDate,
        });

        setShowCalendar(false);
      }, DRAWER_TRANSITION_MS);
    }
  }, [
    startDate,
    endDate,
    optionalEndDate,
    selectedPeriod,
    onDatesSelect,
    setShowPicker,
  ]);

  // update start and end date when initial period changes
  useEffect(() => {
    if (initialPeriod?.since && initialPeriod?.until) {
      setStartDate(initialPeriod.since);
      setEndDate(initialPeriod.until);
      setSelectedPeriod(initialPeriod);
    }
  }, [initialPeriod]);

  const chevronLeft = () => {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11 14L5 8L11 2"
          stroke="#2D2F30"
          strokeWidth="1.3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  };

  const chevronRight = () => {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5 2L11 8L5 14"
          stroke="#2D2F30"
          strokeWidth="1.3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  };

  const renderCalendar = () => {
    return (
      <CalendarContainer>
        <CalendarActions>
          {!emptyPresetPeriods && (
            <Action onClick={() => setShowCalendar(false)}>
              {t('dateFilter.presetPeriods')}
            </Action>
          )}
        </CalendarActions>
        <StyledWrapper>
          <DayPickerRangeController
            startDate={startDate}
            endDate={endDate}
            onDatesChange={onDatesChange}
            focusedInput={focusedInput as any}
            onFocusChange={onFocusChange}
            hideKeyboardShortcutsPanel
            numberOfMonths={2}
            daySize={50}
            initialVisibleMonth={() => moment().subtract(1, 'months')}
            minimumNights={0}
            isOutsideRange={checkDateIsOutsideRange}
            keepOpenOnDateSelect
            navPrev={<NavPrev>{chevronLeft()}</NavPrev>}
            navNext={<NavNext>{chevronRight()}</NavNext>}
            orientation="vertical"
            noBorder={true}
          />
        </StyledWrapper>
      </CalendarContainer>
    );
  };

  const renderPresetPeriods = () => {
    return (
      <>
        {periodOptions.map((key: PeriodKeys) => {
          return (
            <PeriodOption
              selected={
                !!(
                  selectedPeriod &&
                  selectedPeriod.value === PeriodOptions[key].value
                )
              }
              key={`info-row-${PeriodOptions[key].value}`}
              onClick={() => {
                onPeriodSelect(PeriodOptions[key]);
              }}
            >
              {t(PeriodOptions[key].label)}
            </PeriodOption>
          );
        })}
      </>
    );
  };

  const renderPicker = () => {
    return (
      <Drawer
        headerStyle={{ marginBottom: '20px' }}
        isOpen={showPicker}
        onDone={() => onClose()}
        title={t('dateFilter.title')}
      >
        {(showCalendar &&
          selectedPeriod &&
          selectedPeriod.value === PeriodOptions.custom.value) ||
        emptyPresetPeriods
          ? renderCalendar()
          : renderPresetPeriods()}
      </Drawer>
    );
  };

  return (
    <Container
      withMarginTop={withMarginTop}
      ref={forwardRef}
      onClick={() => openDatePicker()}
    >
      {selectedPeriod && selectedPeriod.value && !hidePeriodLabelTip && (
        <Tip>{periodTip}</Tip>
      )}
      <DatePickerInput onClick={() => setShowPicker(!showPicker)}>
        <Left>
          <DateLabel>{startDate?.format('DD/MM/YYYY')}</DateLabel>
          <ArrowRightIcon size={14} />
          <DateLabel>{endDate?.format('DD/MM/YYYY')}</DateLabel>
        </Left>
        <Right>
          <ChevronDown />
        </Right>
      </DatePickerInput>
      {renderPicker()}
    </Container>
  );
};
