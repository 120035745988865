import colors, { primaryColors } from 'constants/colors';
import styled from 'styled-components';

export const CalendarInfoContainer = styled.div<{ isEmpty?: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  /* background: red; */
  width: ${(props) => (props.isEmpty ? '0px' : '160px')};
  height: 100%;
  min-height: 299px;
  border-right: 1px solid ${colors.gray300};
`;

export const CalendarInfoTitle = styled.div`
  color: ${colors.gray850};
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  padding-left: 16px;
  padding-top: 16px;
`;

export const CalendarInfoRows = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 20px;
`;

export const CalendarInfoRow = styled.div<{ selected?: boolean }>`
  color: ${colors.gray850};
  font-size: 14px;
  line-height: 18px;
  min-height: 28px;
  padding: 3px 12px;
  width: 100%;

  :hover {
    background: rgba(45, 47, 48, 0.08);
    color: ${colors.gray850};
    cursor: pointer;
  }
  :active {
    color: ${colors.gray850};
    background: ${colors.gray200};
  }

  ${(props) => (props.selected ? 'background: rgba(45,47,48,0.08);' : '')}
  ${(props) => (props.selected ? 'font-weight: 500;' : '')}

  transition: background 0.1s;
`;

const Nav = `
display: flex;
justify-content: center;
align-items: center;
height: 36px;
width: 36px;
position: absolute;
top: 20px;
font-weight: 600;
border: none;
background: ${colors.white};

border-radius: 50%;

:hover {
  background: ${primaryColors.main};
  color: ${colors.white};
  cursor: pointer;

  svg {
    path {
      stroke: white;
    }
  }
}

:active {
  border: none;
  text-decoration: none;
}

:focus {
  outline: 1px solid ${primaryColors.main};
}
`;

export const NavPrev = styled.button`
  left: 22px;
  ${Nav}
`;

export const NavNext = styled.button`
  right: 22px;
  ${Nav}
`;

export const Tip = styled.span`
  color: ${colors.gray500};
  position: absolute;
  font-size: 11px;
  line-height: 13px;
  bottom: 100%;
  left: 2px;
  margin-bottom: 3px;
`;

export const StyledWrapper = styled.div<{
  withMarginTop?: boolean;
  focused?: boolean;
  active?: boolean;
  largeInput?: boolean;
}>`
  position: relative;
  height: 32px;
  line-height: 1.5;

  ${(props) => props.withMarginTop && `margin-top: 16px;`}

  .DateRangePicker {
    box-shadow: none;
    border-radius: 7px;
    border: 2px solid ${colors.gray50};
    margin-bottom: -2px;

    ${(props) =>
      props.active &&
      `
      border-radius: 5px;
      border: 1px solid ${props.focused ? colors.blue300 : colors.blue200};
      margin: 0px 2px;
    `}
  }

  .DateRangePicker_picker {
    top: 40px !important;
  }

  .DateRangePickerInput__withBorder {
    ${(props) => props.active && 'border: none'};
    ${(props) =>
      props.focused && !props.active
        ? `
          border: 1px solid ${colors.blue200};
        `
        : ''}
  }

  .DateRangePicker .DayPicker > div {
    display: inline-flex;
  }

  .DateRangePicker .DateRangePickerInput {
    border-radius: 5px;
    height: 26px;
    box-sizing: content-box;
    position: relative;
    padding: 2px 0;

    ${(props) => props.active && `background: ${colors.blue50};`}

    .DateInput {
      height: 28px;
      border-radius: 5px;
      width: auto;
      background: transparent;
    }

    .DateInput__small {
      width: auto;
    }

    .DateInput_input {
      font-size: 14px;
      font-weight: 400;
      height: 26px;
      line-height: 18px;
      width: ${(props) => (props.largeInput ? '200px' : '78px')};
      border-radius: 5px;
      text-align: center;
      display: flex;
      margin-left: 2px;
      margin-right: 2px;
      padding: unset;
      background: transparent;

      :hover {
        cursor: pointer;
        background: ${(props) =>
          props.active ? colors.blue100 : colors.blue50};
      }
    }
  }

  .DateRangePickerInput_arrow {
    vertical-align: middle;
  }

  .DateInput_input__small {
    padding: unset;
  }

  .DateInput_input__focused {
    border-color: transparent;
    background: ${primaryColors.fade};
  }

  .DateRangePickerInput__disabled {
    background: transparent;

    .DateInput__disabled {
      .DateInput_input.DateInput_input__disabled {
        color: ${colors.gray300};
        font-style: normal;

        :hover {
          cursor: not-allowed !important;
          background: transparent;
        }
      }
    }
  }

  .DayPicker_transitionContainer {
    height: 330px !important;
    z-index: 0;
  }

  .DayPickerNavigation_button {
    border: none;
  }

  .CalendarDay {
    border: none;
    height: 12px;
    width: 12px;
    :hover {
      background: ${primaryColors.fade};
    }
  }

  .CalendarDay__today {
    background: ${colors.yellow100};
    border-color: ${colors.gray100};
    border-radius: 50%;
    color: ${colors.gray850};
  }

  .CalendarDay__selected {
    background: ${primaryColors.main};
    border-color: ${colors.gray100};
    color: ${colors.white};
    border-radius: 5px;
    :hover {
      background: ${primaryColors.main};
    }

    &.CalendarDay__today {
      border-radius: 50%;
    }
  }

  .CalendarDay__selected_span,
  .CalendarDay__hovered_span {
    border-color: ${colors.gray100};
    color: ${colors.gray850};
    background: ${primaryColors.fade};
  }
`;

export const DatePickerFooter = styled.div`
  background: ${colors.white};
  border-top: 1px solid ${colors.gray300};
  bottom: 0;
  display: flex;
  justify-content: space-between;
  padding: 8px;
  position: absolute;
  right: 0;
  width: 100%;
  z-index: 1;
  gap: 8px;
`;

export const DatePickerFooterButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;

export const DatePickerFooterInfo = styled.div`
  align-items: center;
  font-size: 12px;
`;
