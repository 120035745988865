import colors from 'constants/colors';
import styled from 'styled-components';

export const InvalidText = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: ${colors.red500};
  margin-top: 8px;
`;

export const HelperText = styled.div`
  color: ${colors.gray500};
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
`;
