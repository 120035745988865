import { gql } from '@apollo/client';

export const queryOrgData = gql`
  query OrganizationData {
    viewer {
      id
      organization {
        id
        assetsCount
        integrationsCount
      }
    }
  }
`;
