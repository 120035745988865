import React from 'react';
import styled from 'styled-components';

import colors, {
  alternateColors,
  dangerColors,
  neutralColors,
  primaryColors,
} from 'constants/colors';

import { ButtonProps } from './Button';

// Takes kebab-case variant and generates theme function name
function getVariantTheme(variant: StyledButtonProps['variant'] = 'neutral') {
  return variant.replace(/-([a-z])/g, (g) => {
    return g[1].toUpperCase();
  });
}

type StyledButtonProps = {
  'data-tip'?: string;
  block: ButtonProps['block'];
  className?: string;
  disabled: ButtonProps['disabled'];
  ref: React.Ref<any>;
  size: ButtonProps['size'];
  type: ButtonProps['type'];
  variant: ButtonProps['variant'];
  iconOnly?: boolean;
  dataTestId?: string;
};

export const IconLeftWrapper = styled.div`
  display: flex;
  margin-right: 8px;
  width: 16px;
  height: 16px;
  justify-content: center;
  align-items: center;
`;

export const IconRightWrapper = styled.div`
  display: flex;
  margin-left: 8px;
  width: 16px;
  height: 16px;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  position: relative;
`;

export const ContainerWithButtonMargin = styled(Container)`
  & + button {
    margin-right: 8px;
  }

  button + & {
    margin-left: 8px;
  }
`;

export const StyledButton = styled.button<StyledButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: static;
  align-self: center;

  box-sizing: border-box;
  font-size: 14px;
  line-height: 18px;
  min-width: 32px;
  min-height: 32px;

  border-radius: 5px;
  transition: background 120ms ease-in 0s;

  :active,
  :focus {
    outline: none;
  }

  :hover + .freemium-tooltip {
    /* remove when v2 is propagated */
    opacity: 1 !important;
    visibility: visible;
  }

  :hover {
    .freemium-tooltip {
      opacity: 1 !important;
      visibility: visible;
    }
  }
  ${(props) =>
    props.disabled
      ? `
        cursor: not-allowed;
        pointer-events: all !important;
      `
      : `
        :hover {
          cursor: pointer;
          outline: none;
        }
      `}

  ${(props) => sizeTheme[props.size || 'sm']()}

  ${(props) => props.block && 'display: block;width:100%;'}

  ${(props) => variantTheme[getVariantTheme(props.variant)](props.disabled)}
  ${(props) => (props.iconOnly ? 'padding: 0; min-width: 0;' : '')}
`;

const sizeTheme: { [key: string]: any } = {
  xs: () => `padding: 3px 8px; min-height: 24px;`,
  sm: () => `padding: 5px 8px;`,
  md: () =>
    `padding: 8px 16px;
     line-height: 27px;
     font-size: 18px;`,
};

const variantTheme: { [key: string]: any } = {
  neutral: (disabled: boolean = false) =>
    disabled
      ? `
        background: ${colors.white};
        border: 1px solid ${neutralColors.main};
        color: ${neutralColors.main};
      `
      : `
        border: 1px solid ${neutralColors.main};
        background: ${colors.white};
        color: ${neutralColors.dark};

        :hover, :focus {
          background: ${colors.gray850Hover};
        }

        :active {
          background: rgba(45, 47, 48, 0.16);
        }
      `,

  outlineNeutral: (disabled: boolean = false) =>
    disabled
      ? `
        background: ${colors.white};
        border: 1px solid ${neutralColors.main};
        color: ${neutralColors.main};
      `
      : `
        border: 1px solid ${neutralColors.main};
        background: ${colors.white};
        color: ${neutralColors.dark};

        :hover, :focus {
          background: ${neutralColors.light};
          border: 1px solid ${neutralColors.medium};
        }
      `,

  transparent: (disabled: boolean = false) =>
    disabled
      ? `
        background: transparent;
        border: 1px solid ${neutralColors.main};
        color: ${neutralColors.main};
      `
      : `
        border: none;
        background: transparent;
        color: ${neutralColors.dark};

        :hover, :focus {
          background: rgba(45, 47, 48, 0.08);
        }

        :active {
          background: rgba(45, 47, 48, 0.16);
        }
      `,

  primary: (disabled: boolean = false) =>
    disabled
      ? `
        background: ${primaryColors.light};
        border: 1px solid ${primaryColors.light};
        color: ${colors.white};
      `
      : `
        border: 1px solid ${primaryColors.dark};
        background: ${primaryColors.main};
        color: ${colors.white};

        :hover, :focus {
          background: ${primaryColors.medium};
        }
      `,

  primaryLight: (disabled: boolean = false) =>
    disabled
      ? `
            background: ${colors.blue100};
            border: 1px solid ${colors.white};
            color: ${colors.white};
          `
      : `
            border: 1px solid ${colors.blue600};
            background: ${colors.blue400};
            color: ${colors.white};
    
            :hover, :focus {
              background: ${primaryColors.medium};
            }
          `,

  outlinePrimary: (disabled: boolean = false) =>
    disabled
      ? `
      background: ${colors.white};
      border: 1px solid ${primaryColors.light};
      color: ${primaryColors.light};
    `
      : `
      border: 1px solid ${primaryColors.main};
      background: ${colors.white};
      color: ${primaryColors.main};

      :hover, :focus {
        background: ${primaryColors.fade};
      }
    `,

  outlineFreemium: (disabled: boolean = false) =>
    disabled
      ? `
      border: 1px solid ${colors.purplePlainLight8};
      background: ${colors.purplePlainLight8};
      color: ${colors.purplePlainLight};
    `
      : `
      border: 1px solid ${colors.purplePlainLight};
      background: ${colors.purplePlainLight8};
      color: ${colors.purplePlainLight};

      :hover, :focus {
        background: ${colors.purplePlainLight25};
      }

      :active {
        background: ${colors.purplePlainLight25};
      }
    `,

  outlineStarter: (disabled: boolean = false) =>
    disabled
      ? `
      border: 1px solid ${colors.cyanPlainLight8};
      background: ${colors.cyanPlainLight8};
      color: ${colors.cyanPlainLight};
    `
      : `
      border: 1px solid ${colors.cyanPlainLight};
      background: ${colors.cyanPlainLight8};
      color: ${colors.cyanPlainLight};

      :hover, :focus {
        background: ${colors.cyanPlainLight25};
      }

      :active {
        background: ${colors.cyanPlainLight25};
      }
    `,

  outlinePro: (disabled: boolean = false) =>
    disabled
      ? `
      border: 1px solid ${colors.greenPlainLight8};
      background: ${colors.greenPlainLight8};
      color: ${colors.greenPlainLight};
    `
      : `
      border: 1px solid ${colors.greenPlainLight};
      background: ${colors.greenPlainLight8};
      color: ${colors.greenPlainLight};

      :hover, :focus {
        background: ${colors.greenPlainLight25};
      }

      :active {
        background: ${colors.greenPlainLight25};
      }
    `,

  outlineEnterprise: (disabled: boolean = false) =>
    disabled
      ? `
      border: 1px solid ${colors.orangePlainLight8};
      background: ${colors.orangePlainLight8};
      color: ${colors.orangePlainLight};
    `
      : `
      border: 1px solid ${colors.orangePlainLight};
      background: ${colors.orangePlainLight8};
      color: ${colors.orangePlainLight};

      :hover, :focus {
        background: ${colors.orangePlainLight25};
      }

      :active {
        background: ${colors.orangePlainLight25};
      }
    `,

  danger: (disabled: boolean = false) =>
    disabled
      ? `
        background: ${dangerColors.light};
        border: 1px solid ${dangerColors.light};
        color: ${colors.white};
      `
      : `
        border: 1px solid ${dangerColors.dark};
        background: ${dangerColors.main};
        color: ${colors.white};

        :hover, :focus {
          background: ${dangerColors.medium};
        }
      `,

  outlineDanger: (disabled: boolean = false) =>
    disabled
      ? `
      background: ${colors.white};
      border: 1px solid ${dangerColors.light};
      color: ${dangerColors.light};
      `
      : `
      border: 1px solid ${dangerColors.medium};
      background: ${colors.white};
      color: ${dangerColors.medium};

      :hover, :focus {
        background: ${dangerColors.light};
      }
    `,

  alternate: (disabled: boolean = false) =>
    disabled
      ? `
        background: ${alternateColors.light};
        border: 1px solid ${alternateColors.light};
        color: ${colors.white};
      `
      : `
        background: ${alternateColors.main};
        border: 1px solid ${alternateColors.medium};
        color: ${colors.white};

        :hover, :focus {
          background: ${alternateColors.medium};
        }

        :active {
          background: ${alternateColors.dark};
          border-color: ${alternateColors.dark};
        }
      `,

  outlineAlternate: (disabled: boolean = false) =>
    disabled
      ? `
        background: transparent;
        border: 1px solid ${alternateColors.light};
        color: ${alternateColors.light};
      `
      : `
        background: transparent;
        border: 1px solid ${alternateColors.medium};
        color: ${alternateColors.medium};

        :hover, :focus {
          background: ${colors.cyanPlainLight10};
        }

        :active {
          background: ${colors.cyanPlainLight20};
        }
      `,
  outlineAlternate2: (disabled: boolean = false) =>
    disabled
      ? `
        background: ${colors.white};
        border: 1px solid ${neutralColors.main};
        color: ${colors.black};
      `
      : `
        background: ${colors.white};
        border: 1px solid ${neutralColors.main};
        color: ${colors.black};

        :hover, :focus {
          background: rgba(45, 47, 48, 0.08);
        }

        :active {
          background: rgba(45, 47, 48, 0.16);
        }
      `,
};
