import { useTranslation } from 'react-i18next';
import {
  Badge,
  BadgeContainer,
  Cell,
  Name,
  Row,
} from './FaultsDistributionRow.css';

type FaultsDistributionRowProps = {
  total: number;
  critical: number;
  high: number;
  medium: number;
  low: number;
};

export const FaultsDistributionRow = ({
  total,
  critical,
  high,
  medium,
  low,
}: FaultsDistributionRowProps) => {
  const [t] = useTranslation('assets');
  const unknown = total - low - medium - high - critical;

  const renderBadge = (
    label: string,
    value: number,
    badgeProps: { [k: string]: any },
  ) => {
    return (
      <Cell>
        <Name>{label}</Name>
        <BadgeContainer>
          <Badge {...badgeProps}>{value}</Badge>
        </BadgeContainer>
      </Cell>
    );
  };
  return (
    <Row>
      {renderBadge(t('overview.usage.faults.total'), total, {})}
      {renderBadge(t('overview.usage.faults.critical'), critical, {
        darkRed: true,
      })}
      {renderBadge(t('overview.usage.faults.high'), high, { red: true })}
      {renderBadge(t('overview.usage.faults.medium'), medium, {
        orange: true,
      })}
      {renderBadge(t('overview.usage.faults.low'), low, {
        green: true,
      })}

      {unknown > 0 &&
        renderBadge(t('overview.usage.faults.unknown'), unknown, {
          blue: true,
        })}
    </Row>
  );
};
