import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useKeyPress, useLocalStorage } from 'modules/utils';

import { AssetResultItem, ZoneResultItem } from '../index';

import { useNavigate } from 'react-router-dom';
import { ClearButton, Container, Header, Title } from './index.css';

export type GlobalSearchRecentsProps = {
  onClose: () => void;
};

// eslint-disable-next-line max-statements
export const GlobalSearchRecents = ({ onClose }: GlobalSearchRecentsProps) => {
  const navigate = useNavigate();
  const [cursor, setCursor] = useState(0);

  const downPress = useKeyPress('ArrowDown');
  const upPress = useKeyPress('ArrowUp');
  const enterPress = useKeyPress('Enter');

  const [t] = useTranslation('globalSearch');

  const [recents, setRecents] = useLocalStorage(
    'hiboo__globalSearchRecents',
    [],
  );

  useEffect(() => {
    // Update cursor on down press
    if (recents.length && downPress) {
      setCursor((prevState) =>
        prevState < recents.length - 1 ? prevState + 1 : prevState,
      );
    }
  }, [recents.length, downPress]);

  useEffect(() => {
    // Update cursor on up press
    if (recents.length && upPress) {
      setCursor((prevState) => (prevState > 0 ? prevState - 1 : prevState));
    }
  }, [recents.length, upPress]);

  useEffect(() => {
    // Navigatre on enter press
    if (recents.length && enterPress && recents[cursor]) {
      const datum: any = recents[cursor];

      if (datum && datum.type) {
        if (datum.type === 'asset' && datum.id) {
          navigate(`/assets/${datum.id}/overview`);
        }

        if (datum.type === 'zone' && datum._id) {
          navigate(`/zones/${datum._id}`);
        }

        onClose();
      }
    }
  }, [cursor, recents, enterPress, navigate, onClose]);

  useEffect(() => {
    // If the recents changes reset the cursor to focus on last element
    if (recents.length < cursor) {
      setCursor(recents.length);
    }
  }, [cursor, recents]);

  const handleClose = useCallback(
    (data: any) => {
      const index = recents.findIndex((r: any) => r.id === data.id);

      let nRecents = [];
      if (index === -1) {
        nRecents = [data].concat(recents);
      } else {
        nRecents = [data].concat(recents.filter((r: any) => r.id !== data.id));
      }

      setRecents(nRecents.slice(0, 5)); // max five recents

      onClose();
    },
    [recents, setRecents, onClose],
  );

  const onClear = () => {
    setRecents([]);
  };

  if (!recents || !recents.length) {
    return null;
  }

  return (
    <Container>
      <Header>
        <Title>{t('recent.title')}</Title>
        <ClearButton onClick={onClear}>{t('recent.clear')}</ClearButton>
      </Header>
      {recents.map((d: any, index: number) => {
        if (d.type === 'asset')
          return (
            <AssetResultItem
              active={cursor === index}
              onClose={() => handleClose(d)}
              key={`search-recent-asset-${d.id}`}
              data={d}
            />
          );
        if (d.type === 'zone')
          return (
            <ZoneResultItem
              active={cursor === index}
              onClose={() => handleClose(d)}
              key={`search-recent-zone-${d._id}`}
              data={d}
            />
          );
        return null;
      })}
    </Container>
  );
};
