import { fromJS } from 'immutable';
import { cloneDeep } from 'lodash';
import moment from 'moment';

import { PeriodOptions } from 'modules/common-ui';
import { capitalize } from 'utils/string';

import { ActionType, FLEET_DATA } from '../actions';
import { GraphQLFuelConsumptionRow } from '../types';

export const defaultFilters = {
  period: {
    label: PeriodOptions.last7d.label,
    value: PeriodOptions.last7d.value,
    since: moment().subtract(7, 'day').format('YYYY-MM-DD'),
    until: moment().subtract(1, 'day').format('YYYY-MM-DD'),
  },
};

const initialState = fromJS({
  fleetDataRows: [],
  dailyStats: [],
  totalFleetDataRows: null,
  usageHoursSum: {
    assetsCount: null,
    value: null,
  },
  idleHoursSum: {
    assetsCount: null,
    value: null,
  },
  operatingHoursSum: {
    assetsCount: null,
    value: null,
  },
  fuelUsedSum: {
    assetsCount: null,
    value: null,
  },
  distanceSum: {
    assetsCount: null,
    value: null,
  },
  globalIdlePercent: {
    assetsCount: null,
    value: null,
  },
  globalFuelEfficiency: {
    assetsCount: null,
    value: null,
  },
  co2Sum: {
    assetsCount: null,
    value: null,
  },
  limit: 25,
  offset: 0,
  sorted: [],
  search: '',
  makers: [],
  categories: [],
  models: [],
  sources: [],
  filters: defaultFilters,
  customFieldsV2: [],
  visibleColumns: [
    'name',
    'model',
    'categoryName',
    'fuelUsed',
    'usageHours',
    'operatingHours',
    'idleHours',
    'idlePercent',
    'lastPosition',
  ],
});

type FilterOption = {
  label: string;
  value: string;
};

// eslint-disable-next-line max-statements
export default function fuelConsumptionReducer(
  state = initialState,
  action: ActionType,
) {
  switch (action.type) {
    case FLEET_DATA.RECEIVED_DATA: {
      const {
        makers,
        linkedCategories,
        allModels,
        allSources,
        allEnterprises,
        customFieldsV2,
        allNames,
      } = action.data.group;

      const cleanCategories = linkedCategories
        .map((category: any) => ({ label: category.name, value: category.id }))
        .sort((a: any, b: any) => {
          const aLabel = (a.label || '').toLowerCase();
          const bLabel = (b.label || '').toLowerCase();

          if (aLabel > bLabel) return 1;
          return aLabel < bLabel ? -1 : 0;
        });

      const cleanNames = allNames
        .map((name: string): FilterOption => ({ label: name, value: name }))
        .sort((a: FilterOption, b: FilterOption) => {
          const aLabel = (a.label || '').toLowerCase();
          const bLabel = (b.label || '').toLowerCase();

          if (aLabel > bLabel) return 1;
          return aLabel < bLabel ? -1 : 0;
        });

      const cleanMakers = makers
        .map((m: string): FilterOption => ({ label: capitalize(m), value: m }))
        .sort((a: FilterOption, b: FilterOption) => {
          const aLabel = (a.label || '').toLowerCase();
          const bLabel = (b.label || '').toLowerCase();

          if (aLabel > bLabel) return 1;
          return aLabel < bLabel ? -1 : 0;
        });

      const cleanModels = allModels
        .map((m: string): FilterOption => ({ label: m, value: m }))
        .sort((a: FilterOption, b: FilterOption) => {
          const aLabel = (a.label || '').toLowerCase();
          const bLabel = (b.label || '').toLowerCase();

          if (aLabel > bLabel) return 1;
          return aLabel < bLabel ? -1 : 0;
        });

      const cleanEnterprises = allEnterprises
        .map((m: string): FilterOption => ({ label: m, value: m }))
        .sort((a: FilterOption, b: FilterOption) => {
          const aLabel = (a.label || '').toLowerCase();
          const bLabel = (b.label || '').toLowerCase();

          if (aLabel > bLabel) return 1;
          return aLabel < bLabel ? -1 : 0;
        });

      const sortedSources = [...allSources].sort((a: any, b: any) => {
        if (a > b) return 1;
        return a < b ? -1 : 0;
      });

      return state
        .set('makers', fromJS(cleanMakers))
        .set('categories', fromJS(cleanCategories))
        .set('models', fromJS(cleanModels))
        .set('sources', fromJS(sortedSources))
        .set('enterprises', fromJS(cleanEnterprises))
        .set('customFieldsV2', fromJS(customFieldsV2))
        .set('names', fromJS(cleanNames));
    }

    case FLEET_DATA.RECEIVED_ROWS: {
      const { fleetDataRowsOld } = action.data.group;
      const { rows, totalCount } = cloneDeep(fleetDataRowsOld);

      rows.forEach((r: GraphQLFuelConsumptionRow, i: number) => {
        if (!r.asset) return;
        if (r.asset.customFieldsV2 && r.asset.customFieldsV2.length) {
          r.asset.customFieldsV2.forEach((cf) => {
            rows[i].asset[`customFieldV2_${cf.customFieldId}`] = cf.value;
          });
        }
      });

      return state
        .set('fleetDataRows', fromJS(rows))
        .set('totalFleetDataRows', totalCount);
    }

    case FLEET_DATA.RECEIVED_STATISTICS: {
      const { fleetDataStatistics } = action.data.group;
      const {
        dailyStats,
        usageHoursSum,
        idleHoursSum,
        operatingHoursSum,
        fuelUsedSum,
        co2Sum,
        distanceSum,
        globalIdlePercent,
        globalFuelEfficiency,
      } = cloneDeep(fleetDataStatistics);

      return state
        .set('dailyStats', fromJS(dailyStats))
        .set('usageHoursSum', usageHoursSum)
        .set('idleHoursSum', idleHoursSum)
        .set('operatingHoursSum', operatingHoursSum)
        .set('fuelUsedSum', fuelUsedSum)
        .set('co2Sum', co2Sum)
        .set('distanceSum', distanceSum)
        .set('globalIdlePercent', globalIdlePercent)
        .set('globalFuelEfficiency', globalFuelEfficiency);
    }

    case FLEET_DATA.FETCH_DATA: {
      const { offset, limit, search, sorted } = action.data;

      return state
        .set('offset', offset)
        .set('limit', limit)
        .set('search', search)
        .set('sorted', sorted);
    }

    case FLEET_DATA.FILTER: {
      return state.set('offset', 0).set('filters', fromJS(action.filters));
    }

    case FLEET_DATA.RESET_FILTERS: {
      return state.set('offset', 0).set('filters', fromJS(defaultFilters));
    }

    case FLEET_DATA.SET_VISIBLE_COLUMNS: {
      return state.set('visibleColumns', fromJS(action.columns));
    }

    default:
      return state;
  }
}
