import { useTranslation } from 'react-i18next';
import { useIntercom } from 'react-use-intercom';

import colors from 'constants/colors';
import { ONBOARDING_EXTERNAL_LINKS } from 'constants/externalLinks';
import { BookIcon, CommentIcon, HelpIcon, InlineLink } from 'modules/common-ui';
import layout from 'modules/layout';

import i18n from 'i18n';
import { Container } from './index.css';

export const UserfulLinks = () => {
  const { t } = useTranslation('onboarding');
  const { show, startTour } = useIntercom();

  const inlineLinkStyles = {
    marginLeft: '-7.5px',
    color: colors.gray850,
  };

  const language = i18n.resolvedLanguage;
  const gettingStartedLink =
    language === 'fr'
      ? ONBOARDING_EXTERNAL_LINKS.GETTING_STARTED_FR
      : ONBOARDING_EXTERNAL_LINKS.GETTING_STARTED_EN;

  const openTourGuide = () => {
    const tourGuideId =
      language === 'fr'
        ? ONBOARDING_EXTERNAL_LINKS.TOUR_GUIDE_ID_FR
        : ONBOARDING_EXTERNAL_LINKS.TOUR_GUIDE_ID_EN;
    startTour(tourGuideId);
  };

  return (
    <Container>
      <layout.SectionHeaderSubtitle style={{ fontSize: '11px' }}>
        {t('onboarding.usefulLinks.title')}
      </layout.SectionHeaderSubtitle>
      <InlineLink
        to={gettingStartedLink}
        style={inlineLinkStyles}
        iconLeft={<BookIcon />}
      >
        {t('onboarding.usefulLinks.gettingStarted')}
      </InlineLink>
      <InlineLink
        style={inlineLinkStyles}
        iconLeft={<CommentIcon />}
        onClick={openTourGuide}
      >
        {t('onboarding.usefulLinks.tourGuide')}
      </InlineLink>
      <span className="custom-intercom-launcher">
        <InlineLink
          onClick={show}
          style={inlineLinkStyles}
          iconLeft={<HelpIcon />}
        >
          {t('onboarding.usefulLinks.talkWithUs')}
        </InlineLink>
      </span>
    </Container>
  );
};
