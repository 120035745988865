import { fromJS } from 'immutable';
import moment from 'moment';

import { FAULT_LEVEL_LABELS, PeriodOptions } from 'modules/common-ui';
import { capitalize } from 'utils/string';

import { ActionType, FAULTS_REPORT } from '../actions';

export const defaultFilters = {
  period: {
    label: PeriodOptions.lastMonth.label,
    value: PeriodOptions.lastMonth.value,
    since: moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
    until: moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'),
  },
};

const initialState = fromJS({
  group: {},
  faults: [],
  totalCount: null,
  limit: 25,
  offset: 0,
  search: '',
  sorted: [{ id: 'date', desc: true }],
  categories: [],
  makers: [],
  faultSeverities: [],
  filters: defaultFilters,
  visibleColumns: [
    'name',
    'model',
    'categoryName',
    'label',
    'date',
    'level',
    'description',
    'lastPosition',
  ],
});

type FilterOption = {
  label: string;
  value: string | number;
};

// eslint-disable-next-line max-statements
export default function EquipmentFaultsReducer(
  state = initialState,
  action: ActionType,
) {
  switch (action.type) {
    case FAULTS_REPORT.RECEIVED_DATA: {
      const {
        faults,
        linkedCategories,
        makers,
        allNames,
        allModels,
        allSources,
        allEnterprises,
        allFaultSeverities,
      } = action.data.group;
      const { rows, statistics } = faults;

      const cleanNames = allNames
        .map(
          (name: string): FilterOption => ({
            label: name,
            value: name,
          }),
        )
        .sort((a: FilterOption, b: FilterOption) => {
          const aLabel = (a.label || '').toLowerCase();
          const bLabel = (b.label || '').toLowerCase();

          if (aLabel > bLabel) return 1;
          return aLabel < bLabel ? -1 : 0;
        });

      const cleanCategories = linkedCategories
        .map(
          (category: { name: string; id: number }): FilterOption => ({
            label: category.name,
            value: category.id,
          }),
        )
        .sort((a: FilterOption, b: FilterOption) => {
          const aLabel = (a.label || '').toLowerCase();
          const bLabel = (b.label || '').toLowerCase();

          if (aLabel > bLabel) return 1;
          return aLabel < bLabel ? -1 : 0;
        });

      const cleanMakers = makers
        .map((m: string): FilterOption => ({ label: capitalize(m), value: m }))
        .sort((a: FilterOption, b: FilterOption) => {
          const aLabel = (a.label || '').toLowerCase();
          const bLabel = (b.label || '').toLowerCase();

          if (aLabel > bLabel) return 1;
          return aLabel < bLabel ? -1 : 0;
        });

      const cleanModels = allModels
        .map((m: string): FilterOption => ({ label: m, value: m }))
        .sort((a: FilterOption, b: FilterOption) => {
          const aLabel = (a.label || '').toLowerCase();
          const bLabel = (b.label || '').toLowerCase();

          if (aLabel > bLabel) return 1;
          return aLabel < bLabel ? -1 : 0;
        });

      const cleanEnterprises = allEnterprises
        .map((e: string): FilterOption => ({ label: e, value: e }))
        .sort((a: FilterOption, b: FilterOption) => {
          const aLabel = (a.label || '').toLowerCase();
          const bLabel = (b.label || '').toLowerCase();

          if (aLabel > bLabel) return 1;
          return aLabel < bLabel ? -1 : 0;
        });

      const sortedSources = [...allSources].sort((a: any, b: any) => {
        if (a > b) return 1;
        return a < b ? -1 : 0;
      });

      const sortedFaultSeverities = allFaultSeverities
        .map(
          (faultSeverity: { id: number }): FilterOption => ({
            value: faultSeverity.id,
            label: FAULT_LEVEL_LABELS[faultSeverity.id],
          }),
        )
        .sort((a: FilterOption, b: FilterOption) => {
          const aLabel = (a.label || '').toLowerCase();
          const bLabel = (b.label || '').toLowerCase();

          if (aLabel > bLabel) return 1;
          return aLabel < bLabel ? -1 : 0;
        });

      return state
        .set('group', fromJS(action.data.group))
        .set('faults', fromJS(rows))
        .set('makers', fromJS(cleanMakers))
        .set('categories', fromJS(cleanCategories))
        .set('models', fromJS(cleanModels))
        .set('sources', fromJS(sortedSources))
        .set('enterprises', fromJS(cleanEnterprises))
        .set('names', fromJS(cleanNames))
        .set('faultSeverities', fromJS(sortedFaultSeverities))
        .set('totalCount', statistics.totalCount);
    }

    case FAULTS_REPORT.FETCH_DATA: {
      const { offset, limit, search, sorted } = action.data;

      return state
        .set('offset', offset)
        .set('limit', limit)
        .set('search', search)
        .set('sorted', sorted);
    }

    case FAULTS_REPORT.FILTER: {
      return state.set('offset', 0).set('filters', fromJS(action.filters));
    }

    case FAULTS_REPORT.RESET_FILTERS: {
      return state.set('offset', 0).set('filters', fromJS(defaultFilters));
    }

    case FAULTS_REPORT.SET_VISIBLE_COLUMNS: {
      return state.set('visibleColumns', fromJS(action.columns));
    }

    default:
      return state;
  }
}
