import { gql } from '@apollo/client';

export type GetSharingListContextQuery = {
  viewer: {
    id: string;
    sharing: {
      hasIssuedSharings: boolean;
      hasReceivedSharings: boolean;
    };
  };
};

export const getSharingListContextQuery = gql`
  query GetSharingListContext {
    viewer {
      id
      sharing {
        hasIssuedSharings
        hasReceivedSharings
      }
    }
  }
`;
