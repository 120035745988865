import { FetchApi } from '../client';

export const pricingApi = (fetchApi: FetchApi) => ({
  getCreateSubscription(plan: string, token: string) {
    return fetchApi
      .setToken(token)
      .get(`/app/stripe/create_subscription?plan=${plan}`)
      .then((response) => {
        return response;
      })
      .catch((errorMessage) => {
        throw errorMessage;
      });
  },
  getUpdateSubscription(plan: string, token: string) {
    return fetchApi
      .setToken(token)
      .get(`/app/stripe/update_subscription?plan=${plan}`)
      .then((response) => {
        return response;
      })
      .catch((errorMessage) => {
        throw errorMessage;
      });
  },
  getStopSubscription(token: string) {
    return fetchApi
      .setToken(token)
      .get(`/app/stripe/stop_subscription`)
      .then((response) => {
        return response;
      })
      .catch((errorMessage) => {
        throw errorMessage;
      });
  },
});
