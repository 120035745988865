import {
  Group as Workspace,
  Integration,
  Organization,
  User,
} from 'graphql/types';
import {
  ProcoreCompanyType,
  ProcoreProjectType,
  ProcoreUserType,
} from 'modules/public/Procore';

import { FetchApi } from '../client';
import { OAuthApi } from './types';

type OAuthTokenRequestParamTypes = {
  authorizationCode: string;
  integrationId?: number;
  integrationName: string;
  redirectURI: string;
  token: string;
};

export const oauthApi = (fetchApi: FetchApi) => ({
  getClientIdJohnDeere({ token }: { token: string }): Promise<{
    data: { clientId: string };
  }> {
    return fetchApi
      .setToken(token)
      .get(`/app/oAuth/johnDeere/getClientId`)
      .catch((error) => {
        const errorMessage =
          error && error.message
            ? error.message
            : 'Unable to retrieve JohnDeere Client Id';
        throw new Error(errorMessage);
      });
  },
  getRequestTokensJohnDeere({
    authorizationCode,
    integrationId,
    integrationName,
    redirectURI,
    token,
  }: OAuthTokenRequestParamTypes): Promise<{
    data: { integrationId: number };
  }> {
    return fetchApi
      .setToken(token)
      .get(
        `/app/oAuth/johnDeere/getAccessToken?${
          integrationId ? 'id=' + integrationId + '&' : ''
        }name=${
          integrationName || ''
        }&redirectURI=${redirectURI}&authorizationCode=${authorizationCode}`,
      )
      .then((response) => {
        return response;
      })
      .catch((errorMessage) => {
        throw new Error(
          (typeof errorMessage === 'string' && errorMessage) ||
            'Unable to get a John Deere request token for this integration.',
        );
      });
  },
  getAuthorizationCodeHitachi(
    id: number | null,
    name: string,
    clientId: string,
    clientSecret: string,
    token: string,
  ): Promise<{ integrationId: number }> {
    return fetchApi
      .setToken(token)
      .get(
        `/app/oAuth/hitachi/getAuthorizationCode?${
          id ? 'id=' + id + '&' : ''
        }name=${name || ''}&clientId=${clientId}&clientSecret=${clientSecret}`,
      )
      .catch((error) => {
        const errorMessage =
          error && error.message
            ? error.message
            : 'Unable to get a authorization code for this integration.';
        throw new Error(errorMessage);
      });
  },
  getAccessTokenHitachi(
    id: number,
    authorizationCode: string,
    callbackOriginURL: string,
    token: string,
  ): Promise<{}> {
    return fetchApi
      .setToken(token)
      .get(
        `/app/oAuth/hitachi/getAccessToken?id=${id}&authorizationCode=${authorizationCode}&callbackOriginURL=${callbackOriginURL}`,
      )
      .catch((error) => {
        const errorMessage =
          error && error.message
            ? error.message
            : 'Unable to get a Hitachi access token for this integration.';
        throw new Error(errorMessage);
      });
  },
  getClientIdProcore(
    id: number | null,
    name: string,
    token: string,
  ): Promise<{ clientId: string; integrationId: number }> {
    return fetchApi
      .setToken(token)
      .get(`/app/oAuth/procore/getClientId?id=${id}&name=${name}`)
      .catch((error) => {
        const errorMessage =
          error && error.message
            ? error.message
            : 'Unable to retrieve Procore Client Id';
        throw new Error(errorMessage);
      });
  },
  getClientIdProcorePublic(): Promise<{ clientId: string }> {
    return fetchApi.get(`/public/procore/configure/clientId`).catch((error) => {
      const errorMessage =
        error && error.message
          ? error.message
          : 'Unable to retrieve Procore Client Id';
      throw new Error(errorMessage);
    });
  },
  getAccessTokenProcore(
    id: number,
    code: string,
    token: string,
  ): Promise<{ success: Promise<OAuthApi> }> {
    return fetchApi
      .setToken(token)
      .get(`/app/oAuth/procore/getAccessToken?id=${id}&code=${code}`)
      .then((response) => {
        return response;
      })
      .catch((errorMessage) => {
        throw new Error(
          (typeof errorMessage === 'string' && errorMessage) ||
            'Unable to get a Procore access token for this integration.',
        );
      });
  },
  getAccessTokenProcorePublic(
    code: string,
    procoreCompanyId: string,
  ): Promise<{
    access_token: string;
    procoreUser: ProcoreUserType;
    companies: ProcoreCompanyType[];
    projects: ProcoreProjectType[];
    user: User;
    organization: Organization;
    workspaces: Workspace[];
    integration: Integration;
  }> {
    return fetchApi
      .get(
        `/public/procore/configure/accessToken?code=${code}&procoreCompanyId=${procoreCompanyId}`,
      )
      .then((response) => {
        return response;
      })
      .catch((errorMessage) => {
        throw new Error(
          (typeof errorMessage === 'string' && errorMessage) ||
            'Unable to get a Procore access token.',
        );
      });
  },
  signInWithProcore(
    code: string,
    procoreCompanyId: string | null,
    procoreProjectId: string | null,
  ): Promise<{
    token: string;
    workspaceId: number;
    success: boolean;
  }> {
    return fetchApi
      .get(
        `/public/procore/signin?code=${code}&procoreCompanyId=${procoreCompanyId}${
          procoreProjectId ? `&procoreProjectId=${procoreProjectId}` : ''
        }`,
      )
      .then((response) => {
        return response;
      })
      .catch((errorMessage) => {
        throw new Error(
          (typeof errorMessage === 'string' && errorMessage) ||
            'Unable to signin with Procore.',
        );
      });
  },
});
