import { FetchDataParamsType } from 'modules/tableV7';

export type ActionType = {
  type: string;
  data?: any;
  filters?: any;
  columns?: string[];
};

export const FAULTS_REPORT = {
  RECEIVED_DATA: 'FAULTS_REPORT_RECEIVED_DATA',
  FETCH_DATA: 'FAULTS_REPORT_FETCH_DATA',
  FILTER: 'FAULTS_REPORT_FILTER',
  RESET_FILTERS: 'FAULTS_REPORT_RESET_FILTERS',
  SET_VISIBLE_COLUMNS: 'FAULTS_REPORT_SET_VISIBLE_COLUMNS',
};

export function fetchData({
  pageIndex,
  pageSize,
  search,
  sortBy,
}: FetchDataParamsType) {
  return {
    type: FAULTS_REPORT.FETCH_DATA,
    data: {
      offset: pageIndex * pageSize,
      limit: pageSize,
      search,
      sorted: sortBy,
    },
  };
}

export function receivedData(data: any) {
  return {
    type: FAULTS_REPORT.RECEIVED_DATA,
    data,
  };
}

export function filter(filters: any) {
  return {
    type: FAULTS_REPORT.FILTER,
    filters,
  };
}

export function setVisibleColumns(columns: string[]) {
  return {
    type: FAULTS_REPORT.SET_VISIBLE_COLUMNS,
    columns,
  };
}
