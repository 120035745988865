import { FetchDataParamsType } from 'modules/tableV7';

export type ActionType = {
  type: string;
  data?: any;
  filters?: any;
  columns?: string[];
};

export const FLEET_DATA = {
  FETCH_DATA: 'FLEET_DATA_FETCH_DATA',
  RECEIVED_DATA: 'FLEET_DATA_RECEIVED_DATA',
  RECEIVED_ROWS: 'FLEET_DATA_RECEIVED_ROWS_DATA',
  RECEIVED_STATISTICS: 'FLEET_DATA_RECEIVED_STATISTICS_DATA',
  FILTER: 'FLEET_DATA_FILTER',
  FILTER_DATE: 'FLEET_DATA_FILTER_DATE',
  RESET_FILTERS: 'FLEET_DATA_RESET_FILTERS',
  SET_VISIBLE_COLUMNS: 'FLEET_DATA_SET_VISIBLE_COLUMNS',
};

export function fetchData({
  pageIndex,
  pageSize,
  search,
  sortBy,
}: FetchDataParamsType) {
  return {
    type: FLEET_DATA.FETCH_DATA,
    data: {
      offset: pageIndex * pageSize,
      limit: pageSize,
      search,
      sorted: sortBy,
    },
  };
}

export function receivedData(data: any) {
  return {
    type: FLEET_DATA.RECEIVED_DATA,
    data,
  };
}

export function receivedRows(data: any) {
  return {
    type: FLEET_DATA.RECEIVED_ROWS,
    data,
  };
}

export function receivedStatistics(data: any) {
  return {
    type: FLEET_DATA.RECEIVED_STATISTICS,
    data,
  };
}

export function filter(filters: any) {
  return {
    type: FLEET_DATA.FILTER,
    filters,
  };
}

export function setVisibleColumns(columns: string[]) {
  return {
    type: FLEET_DATA.SET_VISIBLE_COLUMNS,
    columns,
  };
}
