import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import colors from 'constants/colors';

export const GenericStyledInlineLinkCSS = css`
  align-items: center;
  border-radius: 5px;
  color: ${colors.gray500};
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-decoration: none;
  transition: background 120ms ease-in 0s;
  padding: 5px 8px;
  height: 28px;

  :hover {
    background: rgba(56, 58, 60, 0.08);
    color: ${colors.gray500};
    cursor: pointer;
    text-decoration: none;
  }

  :active,
  :focus {
    background: rgba(56, 58, 60, 0.16);
    color: ${colors.gray500};
    cursor: pointer;
    text-decoration: none;
  }
`;

export const StyledInlineLink = styled(Link)<{
  small?: boolean;
}>`
  ${GenericStyledInlineLinkCSS}
  ${(props) => (props.small ? 'padding: 4px 6px;' : 'padding:4px;')};
  ${(props) => props.small && 'height: 24px;'};
`;

export const IconLeftWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  margin-right: 6px;
`;

export const IconRightWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  margin-left: 6px;
`;
