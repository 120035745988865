import assetChronology, {
  AssetChronologyStateType,
} from 'modules/assets/chronology/reducer';
import assetFaults from 'modules/assets/faults/reducer';
import assetHeader from 'modules/assets/header/reducer';
import { assetOverviewReducer as assetOverview } from 'modules/assets/overview/reducer';
import assetUsage from 'modules/assets/usage/reducer';
import language from 'modules/language/reducer';
import faults from 'modules/reports/equipment-faults/reducer';
import fleetData from 'modules/reports/fleet-data/reducer';
import sidenav from 'modules/sidenav/reducer';
import subnav from 'modules/subnav/reducer';

import { userReducer as user, UserStore } from 'modules/user';

export type Store = {
  assetChronology: AssetChronologyStateType;
  assetFaults: any;
  assetHeader: any;
  assetOverview: any;
  assetUsage: any;
  faults: any;
  fleetData: any;
  inventory: any;
  inventoryFavorites: any;
  language: any;
  sidenav: any;
  subnav: any;
  user: UserStore;
};

export const reducers = {
  assetChronology,
  assetFaults,
  assetHeader,
  assetOverview,
  assetUsage,
  faults,
  fleetData,
  language,
  sidenav,
  subnav,
  user,
};
