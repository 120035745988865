export const REDUX_PERSIST_STATE_KEY = 'redux-hiboo';
export const STORAGE_KEY_PREFIX = 'hiboo__';

export const MAPBOX_TOKEN = window.MAPBOX_TOKEN;

export const INTERCOM_TOKEN = 'jk63wov5';

export const GOOGLE_RECAPTCHA_SITE_KEY =
  '6LcaZaImAAAAAHVNNiFOe0mFetPGPIUTDw1TZUj2';

// export const MAP_STYLE_LIGHT = 'mapbox://styles/mpellet/clicumh9f000x01pa7f8ddg3v';
export const MAP_STYLE_LIGHT = 'mapbox://styles/mapbox/light-v11';

// export const MAP_STYLE_STREETS = 'mapbox://styles/mpellet/clicqaf84000m01pa51k7319g';
export const MAP_STYLE_STREETS = 'mapbox://styles/mapbox/streets-v12';

// export const MAP_STYLE_SATELLITE =
//   'mapbox://styles/mpellet/clicujkay000r01r157toavrs';
export const MAP_STYLE_SATELLITE =
  'mapbox://styles/mapbox/satellite-streets-v12';

export const MAP_STYLE_SATELLITE_STADIA =
  'https://tiles-eu.stadiamaps.com/styles/alidade_satellite.json';

export const DEFAULT_MAP_STYLE = MAP_STYLE_SATELLITE;

export const MAX_ZOOM = 20;
export const MARKER_SIZE = 22;

export const defaultGeoJsonAreaStyle = {
  color: 'blue',
  colorSurface: true,
};

export const GEO_ZONE_FILL_OPACITY = 0.05;

export const LINKEDIN_CONVERSION_ID = 7239548;

export default {
  MAPBOX_TOKEN,
};
