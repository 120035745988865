import colors from 'constants/colors';
import styled from 'styled-components';
import { FatCrossIcon } from '../Icons';
import { useClosableBannerLocalStorage } from './useClosableBannerStorage';

const Container = styled.div`
  display: flex;
  width: 100%;
  background: ${colors.offWhite};
  padding: 16px;
  position: relative;
  border-radius: 5px;
  border: 1px solid ${colors.blue100};
`;

const CloseContainer = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;

  align-items: center;
  display: flex;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background: rgba(56, 58, 60, 0.08);
  }
  transition: background 0.1s ease-in-out;
  

  svg {
  padding: 2px 4px 2px 4px;
    path {
      fill: ${colors.gray850};
    }
  }
`;

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Title = styled.div`
    max-width: 880px;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px
    color: ${colors.gray850};
`;

const Body = styled.div`
  max-width: 880px;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  font-family: Inter;
  color: ${colors.gray850};
`;

export type ClosableBannerProps = {
  style?: React.CSSProperties;
  id: string;
  title: string;
  body: string;
};

export const ClosableBanner = ({
  style,
  id,
  title,
  body,
}: ClosableBannerProps) => {
  const { isClosed, close } = useClosableBannerLocalStorage(id);

  const renderContent = () => {
    return (
      <ContentContainer>
        <Title>{title}</Title>
        <Body>{body}</Body>
      </ContentContainer>
    );
  };

  if (isClosed) return null;

  return (
    <Container style={style}>
      <CloseContainer
        onClick={() => {
          close();
        }}
      >
        <FatCrossIcon size={16} />
      </CloseContainer>
      {renderContent()}
    </Container>
  );
};
