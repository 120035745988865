import styled from 'styled-components';

export const ImportantMessage = styled.div`
  font-weight: 700;
  margin-top: 10px;
  margin-bottom: 20px;
`;

export const TextWrapper = styled.div`
  text-align: center;
  margin-bottom: 10px;
`;

export const Footer = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-right: 16px;
  padding-left: 16px;
  padding-top: 8px;
  padding-bottom: 16px;

  button + button {
    margin-top: 8px;
  }
`;
