import { apiClient, UserApi } from 'modules/api-client';
import { FormEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  Button,
  Card,
  Field,
  FieldInputWrapper,
  FieldName,
  FieldValue,
  HibooIcon,
  TextInput,
} from 'modules/common-ui';

import { Form, Header } from '../../index.css';
import { HelperText, InvalidText } from './index.css';

type ResetPasswordFormProps = {
  token: string;
  email: string;
  onSuccess: (user: UserApi) => void;
};

export const ResetPasswordForm = ({
  email,
  token,
  onSuccess,
}: ResetPasswordFormProps) => {
  const navigate = useNavigate();
  const [t] = useTranslation('user');
  const [form, setForm] = useState({ password: '', repeatPassword: '' });
  const [pending, setPending] = useState(false);
  const [error, setError] = useState(null as string | null);
  const [resetTokenValid, setResetTokenValid] = useState(false);
  const [passwordsNotMatching, setPasswordsNotMatching] = useState(false);

  useEffect(() => {
    if (email && token) {
      apiClient.user
        .validateResetToken(email, token)
        .then((response) => {
          setResetTokenValid(response.success);
          setPending(false);
        })
        .catch((err: Error) => {
          setResetTokenValid(false);
          setError(err.message);
          setPending(false);
        });
    }
  }, [email, token]);

  const submitResetPasswordForm = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (
      email &&
      token &&
      form.password &&
      form.repeatPassword &&
      !passwordsNotMatching &&
      !pending
    ) {
      setPending(true);
      apiClient.user
        .resetPassword(email.toLowerCase(), form.password, token)
        .then((user) => {
          onSuccess(user);
          navigate('/map');
        })
        .catch((err: Error) => {
          setError(err.message);
        });
    }
  };

  const handlePasswordChange = (value: string) => {
    setForm({ ...form, password: value });

    if (form.repeatPassword && value !== form.repeatPassword) {
      setPasswordsNotMatching(true);
    } else {
      setPasswordsNotMatching(false);
    }
  };

  const handleRepeatPasswordChange = (value: string) => {
    setForm({ ...form, repeatPassword: value });

    if (form.password && value !== form.password) {
      setPasswordsNotMatching(true);
    } else {
      setPasswordsNotMatching(false);
    }
  };

  return (
    <Card
      style={{
        alignItems: 'center',
        flex: 'unset',
        maxWidth: '400px',
        padding: '24px',
        width: 'calc(100% - 32px)',
      }}
    >
      <HibooIcon />
      <Header>{t('user.resetPassword.form.header')}</Header>
      <Form onSubmit={submitResetPasswordForm}>
        <Field>
          <FieldName>
            <label htmlFor="resetPassword-password" className="col-form-label">
              {t('user.resetPassword.form.password')}
            </label>
          </FieldName>
          <FieldValue>
            <FieldInputWrapper width="100%">
              <TextInput
                fullWidth
                variant="secondary"
                id="resetPassword-password"
                name="password"
                value={form.password}
                onChange={(e) => handlePasswordChange(e.target.value)}
                type="password"
                required
                pattern="(?=.*\d)(?=.*[A-Za-z]).{8,}"
                title={t('user.resetPassword.form.help')}
                autoFocus
                autoComplete="off"
              />
            </FieldInputWrapper>
          </FieldValue>
        </Field>
        <Field>
          <FieldName>
            <label
              htmlFor="resetPassword-repeatPassword"
              className="col-form-label"
            >
              {t('user.resetPassword.form.repeatPassword')}
            </label>
          </FieldName>
          <FieldValue>
            <FieldInputWrapper width="100%">
              <TextInput
                id="resetPassword-repeatPassword"
                name="repeatPassword"
                value={form.repeatPassword}
                onChange={(e) => handleRepeatPasswordChange(e.target.value)}
                required
                fullWidth
                type="password"
                variant="secondary"
                autoComplete="off"
              />
            </FieldInputWrapper>
          </FieldValue>
          {passwordsNotMatching && (
            <InvalidText>
              {t('user.resetPassword.form.passwordsNotMatching')}
            </InvalidText>
          )}
        </Field>
        <HelperText style={{ marginTop: '8px', marginBottom: '16px' }}>
          {t('user.resetPassword.form.help')}
        </HelperText>
        <Button
          block
          variant="outline-alternate"
          type="submit"
          disabled={
            !resetTokenValid ||
            !form.password ||
            !form.repeatPassword ||
            passwordsNotMatching ||
            pending
          }
          style={{ marginTop: '8px' }}
        >
          {!pending
            ? t('user.resetPassword.form.submit.label')
            : t('user.resetPassword.form.submit.loading')}
        </Button>
        {error && <div className="text-danger mt-3">{error}</div>}
      </Form>
    </Card>
  );
};
