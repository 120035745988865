import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useQuery } from '@apollo/client';
import colors from 'constants/colors';
import { HModal, Spinner } from 'modules/common-ui';
import { useDebounce } from 'modules/utils';
import InfiniteScroll from 'react-infinite-scroll-component';

import {
  GlobalSearchInput,
  GlobalSearchRecents,
  GlobalSearchResults,
} from '../../components';
import { GlobalSearch, GlobalSearchQuery, globalSearchQuery } from './gql';
import {
  Container,
  LoadingOverlay,
  NoResultsContainer,
  NoResultsLabel,
  NoResultsSubtitle,
  SearchResultsContainer,
} from './index.css';

type GlobalSearchModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const GlobalSearchModal = ({
  isOpen,
  onClose,
}: GlobalSearchModalProps) => {
  const { t } = useTranslation('globalSearch');

  const [value, setValue] = useState('');
  const [limit, setLimit] = useState(10);
  const [results, setResults] = useState<GlobalSearch[]>([]);
  const debouncedValue = useDebounce(value, 600);

  const { data, refetch, loading } = useQuery<GlobalSearchQuery>(
    globalSearchQuery,
    {
      fetchPolicy: 'no-cache',
      skip: isOpen === false || debouncedValue === '',
      variables: {
        text: debouncedValue,
        limit,
      },
    },
  );

  const search = () => {
    refetch();
  };

  useEffect(() => {
    if (value !== '' && debouncedValue !== '') {
      search();
      setLimit(10);
    }
  }, [debouncedValue]); // eslint-disable-line react-hooks/exhaustive-deps

  const onChange = (e: any) => {
    e.persist();
    setValue(e.target.value);
  };
  const onReset = () => {
    setValue('');
    setLimit(10);
  };

  const fetchMore = (page: number) => {
    setLimit(limit + 10);
    search();
  };

  if (data?.viewer?.workspace?.globalSearch) {
    const newResults = data.viewer.workspace.globalSearch;
    if (newResults.length !== results.length) setResults(newResults);
  }

  return (
    <HModal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={{
        top: '110px',
        transform: 'translate(-50%, -0%)',
      }}
    >
      <Container>
        <GlobalSearchInput
          value={value}
          onReset={onReset}
          onChange={onChange}
        />
        {(!value.length && debouncedValue.length) || !debouncedValue.length ? (
          <GlobalSearchRecents onClose={onClose}></GlobalSearchRecents>
        ) : (
          <SearchResultsContainer id="results-container">
            {results.length === 0 ? (
              <NoResultsContainer>
                <NoResultsLabel>{t('noresults.label')}</NoResultsLabel>
                <NoResultsSubtitle>{t('noresults.subtitle')}</NoResultsSubtitle>
              </NoResultsContainer>
            ) : null}

            <InfiniteScroll
              scrollableTarget="results-container"
              dataLength={results.length || limit}
              next={fetchMore as any}
              hasMore={results.length === limit}
              loader={
                <LoadingOverlay visible={loading && results.length > 0}>
                  <Spinner style={{ background: colors.gray600 }} />
                </LoadingOverlay>
              }
            >
              <GlobalSearchResults
                data={results}
                loading={loading}
                onClose={onClose}
                searchQuery={value}
              />
            </InfiniteScroll>
          </SearchResultsContainer>
        )}
      </Container>
    </HModal>
  );
};
