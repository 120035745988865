import React from 'react';
import styled from 'styled-components';

import colors, { primaryColors } from 'constants/colors';

import { FreemiumButtonProps } from './FreemiumButton';

// Takes kebab-case variant and generates theme function name
function getVariantTheme(variant: StyledButtonProps['variant'] = 'secondary') {
  return variant.replace(/-([a-z])/g, (g) => {
    return g[1].toUpperCase();
  });
}

type StyledButtonProps = {
  'data-tip'?: string;
  block?: FreemiumButtonProps['block'];
  className?: string;
  disabled?: FreemiumButtonProps['disabled'];
  ref?: React.Ref<any>;
  size?: FreemiumButtonProps['size'];
  type?: FreemiumButtonProps['type'];
  variant?: FreemiumButtonProps['variant'];
  iconOnly?: boolean;
  dataTestId?: string;
};

export const FreemiumButtonWrapper = styled.div`
  a,
  a:hover,
  a:visited,
  a:active {
    color: inherit;
    text-decoration: none;
  }
`;

export const StyledButton = styled.button<StyledButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: static;
  align-self: center;

  box-sizing: border-box;
  font-size: 14px;
  line-height: 18px;
  min-width: 32px;
  min-height: 32px;

  border-radius: 5px;
  transition: background 120ms ease-in 0s;

  :active,
  :focus {
    outline: none;
  }

  :hover + .freemium-tooltip {
    visibility: visible !important;
  }

  ${(props) =>
    props.disabled
      ? `
        cursor: not-allowed;
        pointer-events: all !important;
      `
      : `
        :hover {
          cursor: pointer;
          outline: none;
        }
      `}

  ${(props) => sizeTheme[props.size || 'sm']()}

  ${(props) => props.block && 'display: block;width:100%;'}

  ${(props) => variantTheme[getVariantTheme(props.variant)](props.disabled)}
  ${(props) => (props.iconOnly ? 'padding: 0; min-width: 0;' : '')}

  a,
  a:hover,
  a:visited,
  a:active {
    color: inherit;
    text-decoration: none;
  }
`;

const sizeTheme: { [key: string]: any } = {
  xs: () => `padding: 3px 8px; min-height: 24px;`,
  sm: () => `padding: 5px 8px;`,
  md: () =>
    `padding: 8px 16px;
     line-height: 27px;
     font-size: 18px;`,
};

const variantTheme: { [key: string]: any } = {
  secondary: (disabled: boolean = false) =>
    disabled
      ? `
        background: ${colors.gray300};
        border: 1px solid ${colors.gray300};
        color: ${colors.gray300};
      `
      : `
        border: 1px solid ${colors.gray300};
        background: ${colors.white};
        color: ${colors.gray850};

        :hover, :focus {
          background: rgba(45, 47, 48, 0.08);
        }

        :active {
          background: rgba(45, 47, 48, 0.16);
        }
      `,

  outlineSecondary: (disabled: boolean = false) =>
    disabled
      ? `
        background: transparent;
        border: 1px solid ${colors.gray300};
        color: ${colors.gray300};
      `
      : `
        border: 1px solid ${colors.gray300};
        background: transparent;
        color: ${colors.gray850};

        :hover, :focus {
          background: rgba(45, 47, 48, 0.08);
        }

        :active {
          background: rgba(45, 47, 48, 0.16);
        }
      `,

  primary: (disabled: boolean = false) =>
    disabled
      ? `
        background: ${colors.blue100};
        border: 1px solid ${colors.blue100};
        color: ${colors.white};
      `
      : `
        border: 1px solid ${colors.blue600};
        background: ${colors.blue400};
        color: ${colors.white};

        :hover, :focus {
          background: ${colors.blue500};
        }

        :active {
          background: ${colors.blue600};
        }
      `,

  outlinePrimary: (disabled: boolean = false) =>
    disabled
      ? `
      background: transparent;
      border: 1px solid ${colors.blue100};
      color: ${colors.blue100};
    `
      : `
      border: 1px solid ${colors.blue400};
      background: transparent;
      color: ${colors.blue400};

      :hover, :focus {
        background: rgba(69, 105, 247, 0.1);
      }

      :active {
        background: rgba(69, 105, 247, 0.2);
      }
    `,

  outlineFreemium: (disabled: boolean = false) =>
    disabled
      ? `
      border: 1px solid ${primaryColors.fade};
      background: ${primaryColors.light};
      color: ${primaryColors.light};
    `
      : `
      border: 1px solid ${colors.purple400};
      background: ${colors.purple50};
      color: ${colors.purple400};

      :hover, :focus {
        background: ${colors.purple100};
      }
    `,

  outlineStarter: (disabled: boolean = false) =>
    disabled
      ? `
      border: 1px solid ${colors.cyanPlainLight8};
      background: ${colors.cyanPlainLight8};
      color: ${colors.cyanPlainLight};
    `
      : `
      border: 1px solid ${colors.cyanPlainLight};
      background: ${colors.cyanPlainLight8};
      color: ${colors.cyanPlainLight};

      :hover, :focus {
        background: ${colors.cyanPlainLight25};
      }

      :active {
        background: ${colors.cyanPlainLight25};
      }
    `,

  outlinePro: (disabled: boolean = false) =>
    disabled
      ? `
      border: 1px solid ${colors.greenPlainLight8};
      background: ${colors.greenPlainLight8};
      color: ${colors.greenPlainLight};
    `
      : `
      border: 1px solid ${colors.greenPlainLight};
      background: ${colors.greenPlainLight8};
      color: ${colors.greenPlainLight};

      :hover, :focus {
        background: ${colors.greenPlainLight25};
      }

      :active {
        background: ${colors.greenPlainLight25};
      }
    `,

  outlineEnterprise: (disabled: boolean = false) =>
    disabled
      ? `
      border: 1px solid ${colors.orangePlainLight8};
      background: ${colors.orangePlainLight8};
      color: ${colors.orangePlainLight};
    `
      : `
      border: 1px solid ${colors.orangePlainLight};
      background: ${colors.orangePlainLight8};
      color: ${colors.orangePlainLight};

      :hover, :focus {
        background: ${colors.orangePlainLight25};
      }

      :active {
        background: ${colors.orangePlainLight25};
      }
    `,

  danger: (disabled: boolean = false) =>
    disabled
      ? `
        background: ${colors.red100};
        border: 1px solid ${colors.red100};
        color: ${colors.white};
      `
      : `
        border: 1px solid ${colors.red500};
        background: ${colors.red300};
        color: ${colors.white};

        :hover, :focus {
          background: ${colors.red400};
        }

        :active {
          background: ${colors.red500};
        }
      `,

  outlineDanger: (disabled: boolean = false) =>
    disabled
      ? `
      background: transparent;
      border: 1px solid ${colors.red100};
      color: ${colors.red100};
      `
      : `
      border: 1px solid ${colors.red400};
      background: transparent;
      color: ${colors.red400};

      :hover, :focus {
        background: rgba(225, 38, 70, 0.1);
      }

      :active {
        background: rgba(225, 38, 70, 0.2);
      }
    `,

  alternate: (disabled: boolean = false) =>
    disabled
      ? `
        background: ${colors.cyan100};
        border: 1px solid ${colors.cyan100};
        color: ${colors.white};
      `
      : `
        background: ${colors.cyan300};
        border: 1px solid ${colors.cyan400};
        color: ${colors.white};

        :hover, :focus {
          background: ${colors.cyan400};
        }

        :active {
          background: ${colors.cyan500};
          border-color: ${colors.cyan500};
        }
      `,

  outlineAlternate: (disabled: boolean = false) =>
    disabled
      ? `
        background: transparent;
        border: 1px solid ${colors.cyan100};
        color: ${colors.cyan100};
      `
      : `
        background: transparent;
        border: 1px solid ${colors.cyan400};
        color: ${colors.cyan400};

        :hover, :focus {
          background: rgba(7, 180, 246, 0.1);
        }

        :active {
          background: rgba(7, 180, 246, 0.2);
        }
      `,
};
