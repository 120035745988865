import { useTranslation } from 'react-i18next';

import { Button } from 'modules/common-ui';

import { CellSubtitle, CellTitle, Container, StyledLink } from './index.css';

export type NameCellProps = {
  testId?: string;
  title: string;
  subtitle?: string;
  link?: string;
  showLinkOnRowHover?: boolean;
  dataFor?: any;
  dataTip?: any;
  linkTooltip?: string;
  openLinkInNewWindow?: boolean;
  extraUrlParams?: Record<string, string>;
};

export const NameCell = (props: NameCellProps) => {
  const { t } = useTranslation('translation');
  const {
    title,
    subtitle,
    link,
    showLinkOnRowHover = true,
    dataFor = '',
    dataTip = '',
    linkTooltip = '',
    testId = null,
    openLinkInNewWindow = false,
    extraUrlParams,
  } = props;

  let finalUrl = link;
  if (extraUrlParams) {
    const urlSearchParams = new URLSearchParams(extraUrlParams);
    finalUrl = `${link}?${urlSearchParams.toString()}`;
  }

  return (
    <Container data-testid={testId} data-tip={dataTip} data-for={dataFor}>
      <CellTitle>{title}</CellTitle>
      {subtitle && <CellSubtitle>{subtitle}</CellSubtitle>}
      {finalUrl && (
        <StyledLink
          to={finalUrl}
          target={openLinkInNewWindow ? '_blank' : '_self'}
          className={showLinkOnRowHover ? 'visible-on-row-hover' : ''}
        >
          <Button
            tooltipId={title}
            tooltip={linkTooltip}
            variant="outline-neutral"
            size="xs"
          >
            {t('common.open')}
          </Button>
        </StyledLink>
      )}
    </Container>
  );
};
