import React from 'react';
import { useTranslation } from 'react-i18next';

import { Category, TruckIcon } from 'modules/common-ui';

import { AssetSearchResult } from 'modules/global-search/containers/GlobalSearchModal/gql';
import {
  CategoryContainer,
  Label,
  LabelContainer,
  LogoContainer,
  ResultItemContainer,
  StyledLink,
  Subtitle,
} from './index.css';

export type AssetResultItemProps = {
  active?: boolean;
  data: AssetSearchResult;
  id?: string;
  onClose: () => void;
  onMouseEnter?: () => void;
};

export const AssetResultItem: React.FC<
  React.PropsWithChildren<AssetResultItemProps>
> = ({ active = false, data, id, onClose, onMouseEnter }) => {
  const { t } = useTranslation('globalSearch');

  let subtitle = t('results.types.asset');
  if (data.make || data.model) {
    subtitle = `${subtitle} - ${data.make ?? ''} ${data.model ?? ''}`.trim();
  }

  return (
    <StyledLink
      id={id}
      onClick={onClose}
      onMouseEnter={onMouseEnter}
      to={`/assets/${data.id}/overview`}
    >
      <ResultItemContainer active={active}>
        <LogoContainer>
          <TruckIcon size={17} />
        </LogoContainer>
        <LabelContainer>
          <Label>{data.name}</Label>
          <Subtitle>{subtitle}</Subtitle>
        </LabelContainer>
        <CategoryContainer>
          {data.category ? (
            <Category
              category={{
                color: data.category.color!,
                name: data.category.name!,
                id: data.category.id!,
              }}
            />
          ) : null}
        </CategoryContainer>
      </ResultItemContainer>
    </StyledLink>
  );
};
