import { FLAGS } from 'constants/flags';
import { ShareIcon } from 'modules/common-ui';
import { useSharingListContext } from 'modules/sharing/hooks/getSharingListContext/getSharingListContext.hook';
import { useFeature } from 'modules/utils';
import { useTranslation } from 'react-i18next';
import { NavItem } from '../NavItem';

export const NavigationToSharingCenter = () => {
  const [t] = useTranslation('sidenav');

  const [fleetSharingFlag] = useFeature(FLAGS.FLEET_SHARING);
  const { hasIssuedSharings, hasReceivedSharings, loading } =
    useSharingListContext();

  if (loading) return null;

  const shouldShowSharingCenter =
    !!fleetSharingFlag || hasIssuedSharings || hasReceivedSharings;

  if (!shouldShowSharingCenter) return null;

  return (
    <NavItem
      label={t('sidenav.footer.sharing')}
      link="/sharing/center"
      testId="navigation-sharing"
      id="navigation-sharing"
      icon={ShareIcon}
    />
  );
};
