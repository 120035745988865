import { ReactNode } from 'react';
import styled from 'styled-components';
import { ContextualHelpContent } from './ContextualHelpContent';
import { ContextualHelpFooter } from './ContextualHelpFooter';
import { ContextualHelpHeader } from './ContextualHelpHeader';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 359px;
    padding: 24px 16px;
    align-items: flex-start;
    border-radius: 5px;
    border: 1px solid var(--neutral-200, #E5E8EB);
    background: var(--Off-white, #FCFDFD);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.08);
    width: Fixed (359px)px;
    height: Hug (122px)px;
    padding: 24px 16px 24px 16px;
    border-radius: 5px 0px 0px 0px;
    border: 1px 0px 0px 0px;
    opacity: 0px;
`;

export type ContextualHelpProps = {
  header: {
    title: string;
    icon: ReactNode;
  };
  content: {
    label?: string;
    links?: {
      icon: ReactNode;
      label: string;
      onClick: () => void;
    }[];
    media?: ReactNode;
    collapsableSections?: {
      title: string;
      content: string;
    }[];
  };
  footer?: ReactNode;
};

export const ContextualHelp = ({
  header,
  content,
  footer,
}: ContextualHelpProps) => {
  return (
    <Container>
      <ContextualHelpHeader icon={header.icon} title={header.title} />
      <ContextualHelpContent
        label={content.label}
        links={content.links}
        collapsableSections={content.collapsableSections}
        media={content.media}
      />
      <ContextualHelpFooter footer={footer} />
    </Container>
  );
};
