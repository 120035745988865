import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Store } from 'reducers';

export const useOnboarding = () => {
  const assetCount = useSelector<Store>((state) => state.user.assetCount);
  const [isOnboarding, setIsOnboarding] = useState<boolean>(!assetCount);

  useEffect(() => {
    setIsOnboarding(!assetCount);

    return () => {
      setIsOnboarding(false);
    };
  }, [assetCount]);

  return isOnboarding;
};
