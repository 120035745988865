import styled from 'styled-components';

import colors from 'constants/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  .videos-list {
    display: flex;
    justify-content: center;
    gap: 62px;
    padding: 18px;
    padding-bottom: 8px;
  }
`;

export const ScribbleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 130px;
  margin-top: 30px;
  margin-bottom: 12px;
  position: relative;
`;

export const RotatedText = styled.div`
  color: ${colors.gray850};
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  transform: rotate(-30deg);
  height: 52px;
  width: 112px;
  position: absolute;
  top: 30%;
  left: 5%;
`;

export const CurvedShape = styled.img`
  width: 90px;
  position: absolute;
  top: 60%;
  left: 17%;
`;

export const Scribble = styled.img`
  width: 80px;
  position: absolute;
  top: 15%;
  right: 28%;
  z-index: -1;
`;

export const StyledLink = styled.a`
  display: inline-block;
  &:hover {
    text-decoration: none;
  }
`;

export const CardSubtitle = styled.p`
  font-size: 12px;
  line-height: 16px;
  margin-top: 4px;
`;
