import { useCallback, useEffect, useRef, useState } from 'react';

import { useKeyPress, useLocalStorage } from 'modules/utils';

import { GlobalSearch } from 'modules/global-search/containers/GlobalSearchModal/gql';
import { useNavigate } from 'react-router-dom';
import { AssetResultItem, ZoneResultItem } from '../index';

const ITEM_PREFIX = 'item-';

export type GlobalSearchResultsType = {
  data: GlobalSearch[];
  loading?: boolean;
  onClose: () => void;
  searchQuery?: string;
};

// eslint-disable-next-line max-statements
export const GlobalSearchResults = ({
  data,
  loading = false,
  onClose,
  searchQuery = '',
}: GlobalSearchResultsType) => {
  const navigate = useNavigate();
  const [cursor, setCursor] = useState(0);
  const previousSearchAttributes = useRef({ searchQuery, loading });
  const upScrollTimer: any = useRef(null);
  const downScrollTimer: any = useRef(null);

  const downPress = useKeyPress('ArrowDown');
  const upPress = useKeyPress('ArrowUp');
  const enterPress = useKeyPress('Enter');

  const [recents, setRecents] = useLocalStorage(
    'hiboo__globalSearchRecents',
    [],
  );

  const handleClose = useCallback(
    (data: any) => {
      const index = recents.findIndex((r: any) => r.id === data.id);

      let nRecents = [];
      if (index === -1) {
        nRecents = [data].concat(recents);
      } else {
        nRecents = [data].concat(recents.filter((r: any) => r.id !== data.id));
      }

      setRecents(nRecents.slice(0, 5)); // max five recents

      onClose();
    },
    [recents, setRecents, onClose],
  );

  useEffect(() => {
    // Update cursor on down press
    if (data.length && downPress) {
      setCursor((prevState) =>
        prevState < data.length - 1 ? prevState + 1 : prevState,
      );

      if (!downScrollTimer.current) {
        downScrollTimer.current = setInterval(() => {
          setCursor((prevState) =>
            prevState < data.length - 1 ? prevState + 1 : prevState,
          );
        }, 150);
      }
    }

    if (!downPress && downScrollTimer.current) {
      clearInterval(downScrollTimer.current);
      downScrollTimer.current = false;
    }
  }, [data.length, downPress]);

  useEffect(() => {
    // Update cursor on up press
    if (data.length && upPress) {
      setCursor((prevState) => (prevState > 0 ? prevState - 1 : prevState));

      if (!upScrollTimer.current) {
        upScrollTimer.current = setInterval(() => {
          setCursor((prevState) => (prevState > 0 ? prevState - 1 : prevState));
        }, 150);
      }
    }

    if (!upPress && upScrollTimer.current) {
      clearInterval(upScrollTimer.current);
      upScrollTimer.current = false;
    }
  }, [data.length, upPress]);

  useEffect(() => {
    // Navigatre on enter press
    if (data.length && enterPress && data[cursor]) {
      const datum: any = data[cursor];

      if (datum && datum.type) {
        if (datum.type === 'asset' && datum.id) {
          navigate(`/assets/${datum.id}/overview`);
        }

        if (datum.type === 'zone' && datum._id) {
          navigate(`/zones/${datum._id}`);
        }

        handleClose(datum);
      }
    }
  }, [cursor, data, enterPress, handleClose, navigate, onClose]);

  useEffect(() => {
    // If the data changes reset the cursor to focus on last element
    if (data.length < cursor) {
      setCursor(data.length);
    }
  }, [cursor, data]);

  useEffect(() => {
    // Scroll to the content whenever the cursor is moved
    if (downPress || upPress) {
      const element = document.getElementById(`${ITEM_PREFIX}${cursor}`);

      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest',
        });
      }
    }
  }, [cursor, downPress, upPress]);

  useEffect(() => {
    // Reset the cursor once the serach query is modified(I am keeping track of the input as well as the loading state so that cursor won't move to 0 index suddenly before data loads)
    if (
      previousSearchAttributes.current.searchQuery !== searchQuery &&
      previousSearchAttributes.current.loading === loading
    ) {
      setCursor(0);
      previousSearchAttributes.current = { searchQuery, loading };
    }
  }, [loading, previousSearchAttributes, searchQuery]);

  return (
    <>
      {data.map((searchResult: GlobalSearch, index: number) => {
        if (searchResult.__typename === 'AssetSearchResult')
          return (
            <AssetResultItem
              active={cursor === index}
              data={searchResult}
              id={`${ITEM_PREFIX}${index}`}
              key={`search-result-asset-${searchResult.id}`}
              onClose={() => handleClose(searchResult)}
              onMouseEnter={() => setCursor(index)}
            />
          );

        if (searchResult.__typename === 'ZoneSearchResult')
          return (
            <ZoneResultItem
              active={cursor === index}
              data={searchResult}
              id={`${ITEM_PREFIX}${index}`}
              key={`search-result-zone-${searchResult.id}`}
              onClose={() => handleClose(searchResult)}
              onMouseEnter={() => setCursor(index)}
            />
          );

        return null;
      })}
    </>
  );
};
