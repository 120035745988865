import { fromJS } from 'immutable';
import moment from 'moment';

import { FAULT_LEVEL_LABELS, PeriodOptions } from 'modules/common-ui';

import { ActionType, ASSET_FAULTS } from '../actions';
import { GraphQLFault } from '../types';

const _since = moment().subtract(7, 'days').format('YYYY-MM-DD');
const _until = moment().format('YYYY-MM-DD');
const label = PeriodOptions.last7d.label;
const value = PeriodOptions.last7d.value;

const initialState = fromJS({
  // identity data
  assetId: null,

  // data
  faults: [],
  faultsReport: {
    total: null,
    lowNb: null,
    mediumNb: null,
    highNb: null,
    criticalNb: null,
  },
  faultSeverities: [],

  // filters
  since: _since,
  until: _until,
  label,
  value,

  // table,
  search: '',
  filters: {},
});

function craftFaultsReport(faults: GraphQLFault[]) {
  const total = faults.length;
  const lowNb = faults.filter((f) => f.level === 0).length;
  const mediumNb = faults.filter((f) => f.level === 1).length;
  const highNb = faults.filter((f) => f.level === 2).length;
  const criticalNb = faults.filter((f) => f.level === 3).length;

  return {
    total,
    lowNb,
    mediumNb,
    highNb,
    criticalNb,
  };
}

// eslint-disable-next-line max-statements
export default function assetFaultsReducer(
  state = initialState,
  action: ActionType,
) {
  switch (action.type) {
    case ASSET_FAULTS.RECEIVED_DATA: {
      const { workspace } = action.data.viewer;
      if (!workspace) {
        return state;
      }
      const { asset, allFaultSeverities } = workspace;
      if (!asset) {
        return state;
      }
      const { faults } = asset;

      const sortedFaultSeverities = allFaultSeverities
        .map((faultSeverity) => ({
          id: faultSeverity.id,
          label: FAULT_LEVEL_LABELS[faultSeverity.id],
          value: faultSeverity.id,
        }))
        .sort((a, b) => {
          if (a.value > b.value) return 1;
          return a.value < b.value ? -1 : 0;
        });

      return state
        .set('faults', fromJS(faults))
        .set('faultsReport', fromJS(craftFaultsReport(faults)))
        .set('faultSeverities', fromJS(sortedFaultSeverities));
    }

    case ASSET_FAULTS.SEARCH_DATA: {
      const { search } = action;
      return state.set('search', search);
    }

    case ASSET_FAULTS.FILTER_DATE: {
      const { label, value, since, until } = action;

      return state
        .set('label', label)
        .set('value', value)
        .set('since', moment(since).format('YYYY-MM-DD'))
        .set('until', moment(until).format('YYYY-MM-DD'));
    }

    case ASSET_FAULTS.FILTER: {
      return state.set('offset', 0).set('filters', fromJS(action.filters));
    }

    default:
      return state;
  }
}
