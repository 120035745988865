import { gql } from '@apollo/client';

export type GlobalSearch = AssetSearchResult | ZoneSearchResult;

export type AssetSearchResult = {
  __typename?: 'AssetSearchResult';
  category?: Category;
  id: string;
  make?: string;
  model?: string;
  name: string;
};

export type ZoneSearchResult = {
  __typename?: 'ZoneSearchResult';
  category?: Category;
  id: string;
  name: string;
};

type Category = {
  __typename?: 'Category';
  color?: string;
  id?: string;
  name?: string;
};

export type GlobalSearchQuery = {
  viewer: {
    workspace: {
      globalSearch: GlobalSearch[];
    };
  };
};

export const globalSearchQuery = gql`
  query queryGlobalSearch($text: String!, $limit: Int!) {
    viewer {
      id
      workspace {
        id
        globalSearch(text: $text, limit: $limit) {
          ... on AssetSearchResult {
            id
            name
            make
            model
            category {
              id
              name
              color
            }
            __typename
          }
          ... on ZoneSearchResult {
            id
            name
            category {
              id
              name
              color
            }
            __typename
          }
        }
      }
    }
  }
`;
