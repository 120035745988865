import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';

import colors from 'constants/colors';

import { TooltipProps } from './Tooltip';

export const StyledReactTooltip = styled(ReactTooltip)<TooltipProps>`
  pointer-events: auto !important;
  border-radius: 5px !important;
  filter: drop-shadow(0px 3px 4px rgba(209, 213, 219, 0.824021));

  ${(props) => props.padding && `padding: ${props.padding}px !important`};
  ${(props) => props.opacity && `opacity: ${props.opacity} !important`};

  &::after {
    ${(props) =>
      `
      border-${props.place}-color: ${props.background} !important;
      border-${props.place}-width: 9px !important;
      ${props.place === 'top' && 'bottom: -9px !important'};
      ${props.place === 'bottom' && 'top: -9px !important'};
      ${props.place === 'left' && 'right: -9px !important'};
      ${props.place === 'right' && 'left: -9px !important'};
      `}
  }

  &.show {
    opacity: 1;
    z-index: 9999;
    background-color: ${(props) =>
      `${props.background || `rgba(56, 58, 60, 0.85)`} !important`}

  ${(props) =>
    props.small
      ? `
        padding: 4px 8px;
        font-size: 12px;
        line-height: 14px;
        margin-top: -2px;
        `
      : ''}

  ${(props) =>
    props.multilineHtml
      ? `
    font-size: 12px !important;
    line-height: 16px !important;
    ${
      props.extraMargin
        ? `
            margin-left: 30px !important;
            margin-top: 4px !important;
          `
        : 'margin-top: 2px !important;'
    }
    overflow-wrap: break-word;
    padding: 6px 10px 8px !important;
    white-space: normal;
    ${props.width ? `width: ${props.width}px` : `width: 210px`};

    p {
      font-weight: 400;
      margin: 0;
    }

    a {
      color: ${colors.blue100} !important;
      font-weight: 500 !important;
      padding: 0 !important;
      text-decoration: none;
    }
      `
      : ''}
`;
