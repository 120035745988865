import colors from 'constants/colors';
import { InfoIcon } from '../Icons/InfoIcon';
import { InfosContainer, InfosIconContainer, InfosIconText } from './index.css';

export const CallOut = ({
  text,
  marginBottom,
  fontSize,
  fullWidth = false,
}: {
  text: string | JSX.Element;
  marginBottom?: number;
  fontSize?: number;
  fullWidth?: boolean;
}) => (
  <InfosContainer marginBottom={marginBottom} fullWidth={fullWidth}>
    <InfosIconContainer>
      <InfoIcon color={colors.gray850} />
    </InfosIconContainer>
    <InfosIconText fontSize={fontSize}>{text}</InfosIconText>
  </InfosContainer>
);
