import TreeItem, { treeItemClasses } from '@mui/lab/TreeItem';
import colors from 'constants/colors';
import styled from 'styled-components';

export const StyledTreeItem = styled(TreeItem)<{
  level: number;
  withtoggle: string;
  highlighted?: number;
}>`
  & > .MuiTreeItem-content.Mui-selected {
    background: ${colors.gray50};
    &.Mui-focused {
      background: ${colors.gray50};
    }
    :hover {
      background: ${colors.gray50};
    }
  }

  & > .${treeItemClasses.content} {
    ${(props) => (props.highlighted ? `background: ${colors.lightGrey};` : '')}
  }

  & .${treeItemClasses.content} {
    padding: 0;
    padding-top: 8px;
    border-bottom: 1px solid ${colors.gray200};

    :hover {
      background: ${colors.gray50};
    }

    & .${treeItemClasses.label} {
    }

    & .${treeItemClasses.iconContainer} {
      width: 10px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      svg {
        font-size: 16px;
      }
    }
  }
  & .${treeItemClasses.group} {
    margin-left: 0;
  }
`;
