import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  max-width: 50%;
`;

export const Cell = styled.div`
  color: #000000;
  display: flex;
  flex: 1;
  flex-direction: column;
  div + div {
    margin-top: 8px;
  }
  & + & {
    margin-left: 24px;
  }
`;
export const Name = styled.div`
  font-size: 14px;
`;

export const BadgeContainer = styled.div`
  display: flex;
`;

export const Badge = styled.div<{
  darkRed?: boolean;
  red?: boolean;
  orange?: boolean;
  green?: boolean;
  blue?: boolean;
}>`
  background: #e5e8eb;
  ${(props) => (props.darkRed ? 'background: #F77F8E' : '')};
  ${(props) => (props.red ? 'background: #FFB1BA' : '')};
  ${(props) => (props.orange ? 'background: #FEE1AC' : '')};
  ${(props) => (props.green ? 'background: #C2ECD6' : '')};
  ${(props) => (props.blue ? 'background: #B1E4FC' : '')};
  font-size: 14px;
  padding: 6px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 32px;
`;
