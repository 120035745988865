import { apiClient } from 'modules/api-client';
import {
  ApiPricingResponse,
  pricingDefaultValues,
} from 'modules/api-client/flags';
import { FreemiumButton } from 'modules/common-ui';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Store } from 'reducers';
import {
  FooterContainer,
  FooterSection,
  FooterSectionContainer,
  FreemiumButtonWrapper,
  FreemiumSubtitle,
} from './index.css';

export const Footer = () => {
  const [t] = useTranslation('sidenav');

  const [pricingPlan, setPricingPlan] = useState<string>(
    pricingDefaultValues.pricingPlan,
  );
  const [freeTrial, setFreeTrial] = useState<boolean>(
    pricingDefaultValues.freeTrial,
  );
  const [freeTrialStart, setFreeTrialStart] = useState<Date | null>(
    pricingDefaultValues.freeTrialStart,
  );
  const [freeTrialEnd, setFreeTrialEnd] = useState<Date | null>(
    pricingDefaultValues.freeTrialEnd,
  );
  const [freeTrialDays, setFreeTrialDays] = useState(0);
  const userToken: string = useSelector(
    (state: Store) => state.user.token || '',
  );

  const endDate = moment(freeTrialEnd!);
  const todayDate = moment();

  useEffect(() => {
    apiClient.flag
      .getPricingPlanCheck(userToken)
      .then((response: ApiPricingResponse) => {
        setPricingPlan(response.pricingPlan);
        setFreeTrial(response.freeTrial);
        setFreeTrialStart(response.freeTrialStart);
        setFreeTrialEnd(response.freeTrialEnd);

        if (freeTrialStart && freeTrialEnd) {
          setFreeTrialDays(endDate.diff(todayDate, 'days'));
        }
      });
  }, [endDate, todayDate, userToken, freeTrialEnd, freeTrialStart]);

  return (
    <FooterContainer>
      {pricingPlan === 'freemium' && !freeTrial && (
        <FooterSectionContainer id="third-nav-part-container">
          <FooterSection>
            <FreemiumSubtitle>
              {freeTrialEnd
                ? t('sidenav.footer.freemiumSubtitle')
                : t('sidenav.footer.freemiumSubtitleStartFreeTrial')}
            </FreemiumSubtitle>
            <FreemiumButtonWrapper>
              <FreemiumButton
                id="upgrade"
                style={{ fontSize: '13px' }}
                freemiumTooltipButtonLabel={
                  freeTrialEnd
                    ? t('sidenav.footer.freemiumButtonLabel')
                    : t('sidenav.footer.freemiumButtonLabelStartFreeTrial')
                }
                freeTrialLink={freeTrialEnd ? false : true}
              ></FreemiumButton>
            </FreemiumButtonWrapper>
          </FooterSection>
        </FooterSectionContainer>
      )}
      {freeTrial && (
        <FooterSectionContainer id="fourth-nav-part-container">
          <FooterSection>
            <FreemiumSubtitle>
              {t('sidenav.footer.trialDaysRemaining', {
                days: freeTrialDays,
              })}
            </FreemiumSubtitle>
            <FreemiumButtonWrapper>
              <FreemiumButton
                id="upgrade"
                style={{ fontSize: '13px' }}
                freemiumTooltipButtonLabel={t(
                  'sidenav.footer.freemiumButtonLabel',
                )}
              ></FreemiumButton>
            </FreemiumButtonWrapper>
          </FooterSection>
        </FooterSectionContainer>
      )}
    </FooterContainer>
  );
};
