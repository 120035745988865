export const segmentTrackedEvents = {
  INTEGRATION_EVENTS: {
    ADD_SOURCE: 'Add Source Clicked',
    OAUTH_STARTED: 'Integration OAUTH started',
    OAUTH_TOKEN_REQUESTED: 'Integration OAUTH token requested',
    OAUTH_FAILED: 'Integration OAUTH failed',
    INTEGRATION_ADDED: 'Integration Added',
    ADD_INTEGRATION_CANCELLED: '[Add Integration] Cancelled',
    ADD_INTEGRATION_FAILED: '[Add Integration] Failed',
    EDIT_INTEGRATION: '[Edit Integration] Clicked',
    EDIT_INTEGRATION_CANCELLED: '[Edit Integration] Cancelled',
    EDIT_INTEGRATION_FAILED: '[Edit Integration] Failed',
    INTEGRATION_EDITED: 'Integration Edited',
    SOURCE_CLICKED: 'Source Clicked',
  },
  ASSET: {
    NAVIGATE_TO_ASSET_CLICKED: 'Navigate to asset Clicked',
  },
  ONBOARDING_EVENTS: {
    EMPTY_MAP_LOADED: 'Empty Map Loaded',
  },
  ZONE_EVENTS: {
    CHRONOLOGY_EXPORT_CANCELLED: 'Zone Chronology Export Cancelled',
    CHRONOLOGY_EXPORT_CONFIRMED: 'Zone Chronology Export Confirmed',
    CHRONOLOGY_EXPORT_STARTED: 'Zone Chronology Export Started',
  },
  USER_EVENTS: {
    ORGANIZATION_CREATED: 'Organization Created',
    SIGNED_IN: 'Signed In',
    SIGNED_UP_FREETRIAL: 'Signed Up Freetrial',
  },
  SHARING_EVENTS: {
    SHARING_REQUESTED: 'Sharing requested',
  },
};

export default segmentTrackedEvents;
