import React from 'react';

import { Button, HModal, TextArea, TextInput } from 'modules/common-ui';

import { Footer, ImportantMessage, TextWrapper } from './index.css';

type HModalProps = {
  isOpen: boolean;
  basicText?: React.ReactNode;
  importantText?: React.ReactNode;
  inputType?: 'textArea' | 'text';
  inputText?: string;
  inputTextPlaceholder?: string;
  onInputTextChange?: (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => void;
  onConfirmClick: () => void;
  confirmText: React.ReactNode;
  cancelText: React.ReactNode;
  onCancel: () => void;
  confirmDisabled?: boolean;
};

export const HModalWarning = (props: HModalProps) => {
  const {
    isOpen,
    basicText,
    importantText,
    inputType,
    inputText,
    inputTextPlaceholder,
    onInputTextChange,
    onConfirmClick,
    confirmText,
    cancelText,
    onCancel,
    confirmDisabled,
  } = props;

  return (
    <HModal isOpen={isOpen} onRequestClose={onCancel}>
      <HModal.Body>
        {basicText}
        <ImportantMessage>{importantText}</ImportantMessage>
        <TextWrapper>
          {inputType === 'textArea' ? (
            <TextArea
              veryBig
              fullWidth
              type="text"
              variant="secondary"
              value={inputText}
              placeholder={inputTextPlaceholder}
              onChange={onInputTextChange}
            />
          ) : null}
          {inputType === 'text' ? (
            <TextInput
              type="text"
              variant="secondary"
              value={inputText}
              placeholder={inputTextPlaceholder}
              onChange={onInputTextChange}
              textAlign="center"
            />
          ) : null}
        </TextWrapper>
      </HModal.Body>
      <Footer>
        <Button
          variant="outline-danger"
          block
          onClick={onConfirmClick}
          disabled={confirmDisabled}
        >
          {confirmText}
        </Button>
        <Button variant="outline-neutral" block onClick={onCancel}>
          {cancelText}
        </Button>
      </Footer>
    </HModal>
  );
};
