import {
  ApolloClient,
  createHttpLink,
  ServerError,
  ServerParseError,
} from '@apollo/client';
import config from 'config';
import cache from './cache';
import { onError } from './onError';

export const createClient = (token: string | null, disconnect: () => void) => {
  const url = config.apiURL;

  const httpLink = createHttpLink({
    uri: `${url}/graphql`,
    credentials: 'same-origin',
    headers: {
      'x-access-token': token,
    },
  });

  const logoutLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) => {
        if (
          message &&
          ((message.indexOf('Cast to ObjectId failed for value') > -1 &&
            message.indexOf('at path "_id" for model "User"') > -1) ||
            message.indexOf('invalid input syntax for integer') > -1)
        ) {
          disconnect();
        }
        // eslint-disable-next-line no-console
        return console.error(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        );
      });
    }

    if (
      networkError &&
      (networkError as ServerError | ServerParseError).statusCode === 401
    ) {
      disconnect();
    }
  });

  const client = new ApolloClient({
    link: logoutLink.concat(httpLink),
    cache,
  });

  return client;
};
