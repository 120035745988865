import { InMemoryCache } from '@apollo/client';

import { TypedTypePolicies } from '../../../apollo-helpers';
import possibleTypes from '../../possibleTypes.json';

const typePolicies: TypedTypePolicies = {
  AssetCustomFieldType: {
    keyFields: ['id', 'value'],
  },
  WorkspaceTyreSnapshot: {
    merge: true,
  },
  Co2Report: {
    keyFields: [],
  },
  Sharing: {
    keyFields: [],
  },
  IssuedSharingDataFilters: {
    keyFields: [],
  },
};

const cache = new InMemoryCache({
  possibleTypes,
  typePolicies,
});

export default cache as any;
