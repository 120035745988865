import React from 'react';
import { useTranslation } from 'react-i18next';

import { Category, ZonesIcon } from 'modules/common-ui';

import { ZoneSearchResult } from 'modules/global-search/containers/GlobalSearchModal/gql';
import {
  CategoryContainer,
  Label,
  LabelContainer,
  LogoContainer,
  ResultItemContainer,
  StyledLink,
  Subtitle,
} from './index.css';

export type ZoneResultItemProps = {
  active?: boolean;
  data: ZoneSearchResult;
  id?: string;
  onClose: () => void;
  onMouseEnter?: () => void;
};

export const ZoneResultItem: React.FC<
  React.PropsWithChildren<ZoneResultItemProps>
> = ({ active = false, data, id, onClose, onMouseEnter }) => {
  const { t } = useTranslation('globalSearch');

  return (
    <StyledLink
      id={id}
      onClick={onClose}
      onMouseEnter={onMouseEnter}
      to={`/zones/${data.id}`}
    >
      <ResultItemContainer active={active}>
        <LogoContainer>
          <ZonesIcon size={17} />
        </LogoContainer>
        <LabelContainer>
          <Label>{data.name}</Label>
          <Subtitle>{`${t('results.types.zone')}`}</Subtitle>
        </LabelContainer>
        <CategoryContainer>
          {data.category ? (
            <Category
              category={{
                color: data.category.color!,
                name: data.category.name!,
                id: data.category.id!,
              }}
            />
          ) : null}
        </CategoryContainer>
      </ResultItemContainer>
    </StyledLink>
  );
};
