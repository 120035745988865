import { GOOGLE_RECAPTCHA_SITE_KEY } from 'config/constants';
import { Button, CircularSpinner, TextInput } from 'modules/common-ui';
import {
  Card,
  CardFooterContainer,
  CardHeader,
  FieldTitle,
  PrivacyPolicyContainer,
  StyledLink,
} from 'modules/signup/common';
import { useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';

export type AccountFormProps = {
  firstName?: string;
  lastName?: string;
  email?: string;
  password?: string;
  // phone?: string;
  loading: boolean;
  onSubmit: () => void;
  onValueChange: (key: string, v: string | null) => void;
};

export const AccountForm = ({
  firstName,
  lastName,
  email,
  password,
  // phone,
  loading,
  onSubmit,
  onValueChange,
}: AccountFormProps) => {
  const [t] = useTranslation('signup');

  const recaptchaRef = useRef(null);

  return (
    <div>
      <Card width={390}>
        <CardHeader displayLogo={true} title={t('form.title')} />

        <FieldTitle>{t('form.fields.firstName.label')} *</FieldTitle>
        <TextInput
          variant="secondary"
          fullWidth
          placeholder={t('form.fields.firstName.placeholder')}
          value={firstName}
          onChange={(e) => {
            onValueChange('firstName', e.target.value);
          }}
        />

        <FieldTitle>{t('form.fields.lastName.label')} *</FieldTitle>
        <TextInput
          variant="secondary"
          fullWidth
          placeholder={t('form.fields.lastName.placeholder')}
          value={lastName}
          onChange={(e) => {
            onValueChange('lastName', e.target.value);
          }}
        />

        <FieldTitle>{t('form.fields.email.label')} *</FieldTitle>
        <TextInput
          variant="secondary"
          fullWidth
          type="email"
          placeholder={t('form.fields.email.placeholder')}
          value={email}
          onChange={(e) => {
            onValueChange('email', e.target.value);
          }}
        />

        <FieldTitle>{t('form.fields.password.label')} *</FieldTitle>
        <TextInput
          variant="secondary"
          fullWidth
          type="password"
          placeholder="xxxx"
          value={password}
          onChange={(e) => {
            onValueChange('password', e.target.value);
          }}
        />
        <br />
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={GOOGLE_RECAPTCHA_SITE_KEY}
          onChange={(value) => {
            onValueChange('recaptcha', value);
          }}
        />

        {/* <FieldTitle>Phone Number</FieldTitle>
      <TextInput
        variant='secondary'
        fullWidth
        placeholder='test'
        value={phone}
        type="tel"
        pattern="+[0-9]{3}-[0-9]{2}-[0-9]{3}"
        onChange={(e) => { onValueChange('phone', e.target.value) }}
      /> */}

        <CardFooterContainer>
          <Button
            variant="outline-primary"
            disabled={loading}
            iconOnly={loading}
            iconLeft={loading ? <CircularSpinner /> : null}
            onClick={() => {
              onSubmit();
            }}
          >
            {t('form.submitLabel')}
          </Button>
          {/* <ClassicLink */}
          <StyledLink to="/login">{t('form.loginLabel')}</StyledLink>
        </CardFooterContainer>
      </Card>
      <PrivacyPolicyContainer
        dangerouslySetInnerHTML={{
          __html: t('form.termsOfService', {
            interpolation: { escapeValue: false },
          }),
        }}
      />
    </div>
  );
};
