import { useTranslation } from 'react-i18next';

import {
  PartnersFooterContainer,
  PartnersFooterLogos,
  PartnersFooterText,
} from './PartnersFooter.css';

export const PartnersFooter = () => {
  const [t] = useTranslation('signup');

  return (
    <PartnersFooterContainer>
      <PartnersFooterText
        dangerouslySetInnerHTML={{
          __html: t('footer.text', { interpolation: { escapeValue: false } }),
        }}
      />
      <PartnersFooterLogos>
        <img
          alt="beautiful partners companies logos"
          src="https://uploads-ssl.webflow.com/5eb66a3cf92b58f9ef0e9b78/60c0cd20bd77194145addb00_Hiboo-customers-logo.png"
        />
      </PartnersFooterLogos>
    </PartnersFooterContainer>
  );
};
