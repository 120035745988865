import { fromJS } from 'immutable';
import moment from 'moment-business-days';

import { ASSET_OVERVIEW, AssetOverviewAction } from '../actions';
import {
  EvolutionDataType,
  GraphQLDailyCO2,
  GraphQLDailyFuel,
  GraphQLDailyUsage,
  GraphQLFault,
} from '../types';

const _since = moment().subtract(7, 'day').format('YYYY-MM-DD');
const _until = moment().format('YYYY-MM-DD');
const _nbWorkingDays = moment(_until).businessDiff(moment(_since));

const initialState = fromJS({
  // identity data
  assetId: null,
  name: null,
  make: null,
  model: null,
  modelManufacturer: null,
  serialNumber: null,
  description: null,
  category: null,
  categories: [],
  lastDataDate: null,
  latestPosition: null,
  lastUpdatedAt: null,
  source: null,
  dataSources: [],
  year: null,
  enterprise: null,

  cumulativeUsageHoursDate: null,
  cumulativeUsageHours: null,
  cumulativeFuelUsedDate: null,
  cumulativeFuelUsed: null,
  cumulativeDistanceDate: null,
  cumulativeDistance: null,
  cumulativePayloadTotal: null,
  cumulativeLoadCount: null,
  cumulativePowerTakeOffHours: null,

  isDataMissing: false,

  // filters
  since: _since,
  until: _until,
  nbWorkingDays: _nbWorkingDays,

  // raw data from usage query
  faults: [],
  dailyFuels: [],
  dailyUsages: [],

  // aggregated data ready for displaying usage
  faultsReport: {
    total: null,
    lowNb: null,
    mediumNb: null,
    highNb: null,
    criticalNb: null,
  },
  exploitation: {
    usageHours: null,
    operatingHours: null,
    idleHours: null,
    fuelUsed: null,
  },
  efficiency: {
    nbDays: null,
    usagePercent: null, // taux d'utilisation
    idlePercent: null, // taux de ralenti
    hourlyConsumption: null, //
    output: null, // "rendement"
  },
  evolution: {
    data: null,
  },
});

function craftFaultsReport(faults: GraphQLFault[]) {
  const total = faults.length;
  const lowNb = faults.filter((f) => f.level === 0).length;
  const mediumNb = faults.filter((f) => f.level === 1).length;
  const highNb = faults.filter((f) => f.level === 2).length;
  const criticalNb = faults.filter((f) => f.level === 3).length;

  return {
    total,
    lowNb,
    mediumNb,
    highNb,
    criticalNb,
  };
}

function computeRatio(numerator: number, denominator: number) {
  if (numerator == null || denominator == null || denominator <= 0) {
    return null;
  }
  return numerator / denominator;
}

// eslint-disable-next-line max-statements
function craftAggregates(
  dailyUsages: GraphQLDailyUsage[],
  dailyFuels: GraphQLDailyFuel[],
  dailyCO2s: GraphQLDailyCO2[],
  nbWorkingDays: number,
  hourlyConsumption: number | null,
) {
  let nbDays: any = dailyUsages.length ? 0 : null;
  let usageHours: any = dailyUsages.length ? 0 : null;
  let operatingHours: any = null;
  let idleHours: any = null;
  let fuelUsed: any = dailyFuels.length ? 0 : null;
  let co2: any = dailyCO2s.length ? 0 : null;

  const usageHoursGraphData: EvolutionDataType[] = [];
  const fuelUsedGraphData: any = [];

  dailyFuels.forEach((df) => {
    fuelUsed += df.fuelUsed;

    fuelUsedGraphData.push({
      date: df.date,
      fuelUsed: df.fuelUsed,
    });
  });

  dailyCO2s.forEach((dailyCO2) => {
    co2 += dailyCO2.weight;
  });

  dailyUsages.forEach((du) => {
    if (du.usageHours && du.usageHours > 0) {
      nbDays += 1;
    }
    usageHours += du.usageHours;
    if (du.operatingHours !== null) {
      operatingHours += du.operatingHours;
    }
    if (du.idleHours !== null) {
      idleHours += du.idleHours;
    }

    usageHoursGraphData.push({
      date: du.date,
      usageHours: du.usageHours,
      idleHours: du.idleHours,
      operatingHours: du.operatingHours,
    });
  });

  let usagePercent = null;
  if (nbDays != null && nbWorkingDays != null) {
    if (nbDays === 0) {
      usagePercent = 0;
    } else if (nbWorkingDays === 0) {
      // should not happen
      // let usagePercent as null
    } else {
      usagePercent = Math.min(nbDays / nbWorkingDays, 1);
    }
  }

  return {
    exploitation: {
      usageHours,
      operatingHours,
      idleHours,
      fuelUsed,
      co2,
    },
    efficiency: {
      usagePercent,
      idlePercent: computeRatio(idleHours, idleHours + operatingHours),
      hourlyConsumption,
      nbDays,
      output: computeRatio(usageHours, nbDays),
    },
    evolution: {
      data: usageHoursGraphData,
      fuel: fuelUsedGraphData,
    },
  };
}

/* eslint-disable-next-line complexity, max-statements */
export function assetOverviewReducer(
  state = initialState,
  action: AssetOverviewAction,
) {
  switch (action.type) {
    case ASSET_OVERVIEW.RECEIVED_USAGE_DATA: {
      const { workspace } = action.data.viewer;
      const { asset } = workspace;
      if (!asset) {
        // do something
        return state;
      }

      const { faults, dailyCO2s, dailyUsages, dailyFuels } = asset;
      const faultsReport = craftFaultsReport(faults);
      const { exploitation, efficiency, evolution } = craftAggregates(
        dailyUsages,
        dailyFuels,
        dailyCO2s,
        state.get('nbWorkingDays'),
        asset.hourlyConsumption,
      );

      const isDataMissing =
        state.get('isDataMissing') ||
        dailyUsages.length < state.get('nbWorkingDays') ||
        dailyFuels.length < state.get('nbWorkingDays');
      const latestCumulativeFuel = asset.latestCumulativeFuel || {
        liters: null,
        date: null,
      };

      const latestCumulativeDistance = asset.latestCumulativeDistance || {
        kilometers: null,
        date: null,
      };

      const latestCumulativeUsageHour = asset.latestCumulativeUsageHour || {
        hours: null,
        date: null,
      };

      const latestCumulativePayload = asset.latestCumulativePayload || {
        tonnes: null,
        date: null,
      };

      const latestCumulativeLoadCount = asset.latestCumulativeLoadCount || {
        count: null,
        date: null,
      };

      const latestCumulativePowerTakeOffHour =
        asset.latestCumulativePowerTakeOffHour || {
          hours: null,
          date: null,
        };

      return state
        .set('faults', fromJS(faults))
        .set('dailyUsages', fromJS(dailyUsages))
        .set('exploitation', fromJS(exploitation))
        .set('efficiency', fromJS(efficiency))
        .set('evolution', fromJS(evolution))
        .set('faultsReport', fromJS(faultsReport))
        .set('isDataMissing', isDataMissing)
        .set('cumulativeFuelUsed', latestCumulativeFuel.liters)
        .set('cumulativeFuelUsedDate', latestCumulativeFuel.date)
        .set('cumulativeDistance', latestCumulativeDistance.kilometers)
        .set('cumulativeDistanceDate', latestCumulativeDistance.date)
        .set('cumulativeUsageHours', latestCumulativeUsageHour.hours)
        .set('cumulativeUsageHoursDate', latestCumulativeUsageHour.date)
        .set('payloadTotal', latestCumulativePayload.tonnes)
        .set('payloadTotalDate', latestCumulativePayload.date)
        .set('loadCount', latestCumulativeLoadCount.count)
        .set('loadCountDate', latestCumulativeLoadCount.date)
        .set('powerTakeOff', latestCumulativePowerTakeOffHour.hours)
        .set('powerTakeOffDate', latestCumulativePowerTakeOffHour.date);
    }

    case ASSET_OVERVIEW.RECEIVED_DETAILS_DATA: {
      const { workspace } = action.data.viewer;
      const { asset } = workspace;

      if (!asset) {
        // do something
        return state;
      }

      const { categories } = workspace;

      const {
        batteryLevel,
        category,
        latestCumulativeFuel,
        latestCumulativeDistance,
        latestCumulativeUsageHour,
        latestCumulativePayload,
        latestCumulativeLoadCount,
        latestCumulativePowerTakeOffHour,
        dataSources,
        device,
        energyType,
        enterprise,
        id,
        lastDataDate,
        latestPosition,
        make,
        model,
        modelManufacturer,
        name,
        serialNumber,
        source,
        year,
      } = asset;

      const { indicator } = batteryLevel || { indicator: '' };

      const { description } = device ? device : { description: '' };

      let distance = null;
      let distanceDate = null;
      let fuelUsed = null;
      let fuelUsedDate = null;
      let usageHours = null;
      let usageHoursDate = null;
      let payloadTotal = null;
      let payloadTotalDate = null;
      let loadCount = null;
      let loadCountDate = null;
      let powerTakeOff = null;
      let powerTakeOffDate = null;
      if (latestCumulativeFuel) {
        // eslint-disable-next-line
        fuelUsed = latestCumulativeFuel.liters;
        fuelUsedDate = latestCumulativeFuel.date;
      }
      if (latestCumulativeDistance) {
        // eslint-disable-next-line
        distance = latestCumulativeDistance.kilometers;
        distanceDate = latestCumulativeDistance.date;
      }
      if (latestCumulativeUsageHour) {
        usageHours = latestCumulativeUsageHour.hours;
        usageHoursDate = latestCumulativeUsageHour.date;
      }
      if (latestCumulativePayload) {
        payloadTotal = latestCumulativePayload.tonnes;
        payloadTotalDate = latestCumulativePayload.date;
      }
      if (latestCumulativeLoadCount) {
        loadCount = latestCumulativeLoadCount.count;
        loadCountDate = latestCumulativeLoadCount.date;
      }
      if (latestCumulativePowerTakeOffHour) {
        powerTakeOff = latestCumulativePowerTakeOffHour.hours;
        powerTakeOffDate = latestCumulativePowerTakeOffHour.date;
      }

      const isDataMissing =
        source !== 'tripy' &&
        source !== 'marlink' &&
        (!!state.get('isDataMissing') ||
          !latestCumulativeFuel ||
          !latestCumulativeFuel.id ||
          !latestCumulativeUsageHour ||
          !latestCumulativeUsageHour.id);

      return state
        .set('assetId', id)
        .set('name', name)
        .set('categories', fromJS(categories))
        .set('category', fromJS(category))
        .set('energyType', fromJS(energyType))
        .set('lastDataDate', lastDataDate)
        .set('latestPosition', fromJS(latestPosition))
        .set('batteryIndicator', indicator)
        .set('source', source)
        .set('dataSources', fromJS(dataSources))
        .set('description', description)
        .set('make', make)
        .set('serialNumber', serialNumber)
        .set('model', model)
        .set('modelManufacturer', modelManufacturer)
        .set('year', year)
        .set('enterprise', enterprise)
        .set('cumulativeUsageHours', usageHours)
        .set('cumulativeUsageHoursDate', usageHoursDate)
        .set('cumulativeFuelUsed', fuelUsed)
        .set('cumulativeFuelUsedDate', fuelUsedDate)
        .set('cumulativeDistance', distance)
        .set('cumulativeDistanceDate', distanceDate)
        .set('payloadTotal', payloadTotal)
        .set('payloadTotalDate', payloadTotalDate)
        .set('loadCount', loadCount)
        .set('loadCountDate', loadCountDate)
        .set('powerTakeOff', powerTakeOff)
        .set('powerTakeOffDate', powerTakeOffDate)
        .set('isDataMissing', isDataMissing);
    }

    case ASSET_OVERVIEW.RECEIVED_DETAILS_DATA_MOBILE: {
      const { workspace } = action.data.viewer;
      const { asset } = workspace;

      if (!asset) {
        return state;
      }

      const {
        category,
        dataSources,
        device,
        enterprise,
        id,
        lastDataDate,
        latestPosition,
        make,
        model,
        modelManufacturer,
        name,
        serialNumber,
        source,
        year,
      } = asset;

      const { description, batteryIndicator } = device
        ? device
        : { description: '', batteryIndicator: '' };

      return state
        .set('assetId', id)
        .set('batteryIndicator', batteryIndicator)
        .set('category', fromJS(category))
        .set('dataSources', fromJS(dataSources))
        .set('description', description)
        .set('enterprise', enterprise)
        .set('lastDataDate', lastDataDate)
        .set('latestPosition', fromJS(latestPosition))
        .set('make', make)
        .set('model', model)
        .set('modelManufacturer', modelManufacturer)
        .set('name', name)
        .set('serialNumber', serialNumber)
        .set('source', source)
        .set('year', year);
    }

    case ASSET_OVERVIEW.FILTER_DATE: {
      const { since, until } = action;

      const mSince = moment.utc(since).startOf('day');
      const mUntil = moment.utc(until).startOf('day');
      const nbWorkingDays = mUntil.businessDiff(mSince);

      return state
        .set('since', moment(since).format('YYYY-MM-DD'))
        .set('until', moment(until).format('YYYY-MM-DD'))
        .set('nbWorkingDays', nbWorkingDays)
        .set('isDataMissing', false);
    }

    case ASSET_OVERVIEW.CLOSE_WARNING: {
      return state.set('isDataMissing', false);
    }

    case ASSET_OVERVIEW.UPDATE_CACHE: {
      const {
        category,
        enterprise,
        make,
        model,
        modelManufacturer,
        name,
        year,
        energyType,
      } = action.data;

      return state
        .set('category', fromJS(category))
        .set('energyType', fromJS(energyType))
        .set('make', make)
        .set('name', name)
        .set('model', model)
        .set('modelManufacturer', modelManufacturer)
        .set('year', year)
        .set('enterprise', enterprise);
    }

    default:
      return state;
  }
}
