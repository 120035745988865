export const FILTER_TYPES = {
  ASSET_NAME: 'assetName' as const,
  BRAND: 'brand' as const,
  CATEGORY: 'category' as const,
  ENTERPRISE: 'enterprise' as const,
  FAULT_SEVERITY: 'faultSeverity' as const,
  MODEL: 'model' as const,
  SOURCE: 'source' as const,
  FAVORITE: 'favorite' as const,
  CUSTOM_FIELD_V2: 'customFieldsV2' as const,
  ZONE: 'zone' as const,
};

export type FilterTypeKey = (typeof FILTER_TYPES)[keyof typeof FILTER_TYPES];

export const FAVORITES_FILTER_VALUES = {
  YES: 'yes',
  NO: 'no',
};

export const SEGMENT_SUPPORTED_FILTERS = [
  FILTER_TYPES.ASSET_NAME,
  FILTER_TYPES.BRAND,
  FILTER_TYPES.CATEGORY,
  FILTER_TYPES.ENTERPRISE,
  FILTER_TYPES.MODEL,
  FILTER_TYPES.SOURCE,
  FILTER_TYPES.FAVORITE,
  FILTER_TYPES.CUSTOM_FIELD_V2,
];
