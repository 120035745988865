import { GraphQLAssetFaults } from '../types';

export type ActionType = {
  type: string;
  data: GraphQLAssetFaults;
  since: string;
  until: string;
  filters: any;
  search: string;
  label: string;
  value: string;
};

export const ASSET_FAULTS = {
  RECEIVED_DATA: 'ASSET_FAULTS_RECEIVED_DATA',
  SEARCH_DATA: 'ASSET_FAULTS_REPORT_SEARCH_DATA',
  FILTER_DATE: 'ASSET_FAULTS_FILTER_DATE',
  FILTER: 'ASSET_FAULTS_FILTER',
};

export function receivedData(data: GraphQLAssetFaults) {
  return {
    type: ASSET_FAULTS.RECEIVED_DATA,
    data,
  };
}

export function filter(filters: any) {
  return {
    type: ASSET_FAULTS.FILTER,
    filters,
  };
}
