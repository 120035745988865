import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FaAngleDown from 'react-icons/lib/fa/angle-down';
import OutsideClickHandler from 'react-outside-click-handler';
import { usePopper } from 'react-popper';

import { EllipsisToolTip } from 'modules/common-ui';

import { Portal } from '@mui/material';
import {
  Container,
  Label,
  Left,
  List,
  ListHeader,
  ListItem,
  Right,
  Select,
} from './WorkspaceList.css';

export type WorkspaceAccessListPropType = {
  alignMenu?: 'left' | 'right';
  light?: boolean;
  listHeader?: string;
  workspaces: Array<{ id: number; name: string }>;
  usePortal?: boolean;
  portalContainerId?: string;
};

export const WorkspaceList = ({
  alignMenu = 'right',
  light = false,
  listHeader,
  workspaces,
  usePortal = false,
  portalContainerId,
}: WorkspaceAccessListPropType) => {
  const { t } = useTranslation('translation');

  const [display, setDisplay] = useState(false);

  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null,
  );
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'right-end',
  });

  const getWorkspaceValue = useCallback(() => {
    const workspacesCount = workspaces.length;
    if (workspacesCount > 2) {
      const wsName1 = workspaces[0] ? workspaces[0].name : '';
      const wsName2 = workspaces[1] ? workspaces[1].name : '';

      return `${wsName1}, ${wsName2}, ${
        workspacesCount === 3
          ? t('common.andMore', {
              count: 1,
            })
          : t('common.andMore', {
              count: workspacesCount - 2,
            })
      }`;
    } else {
      return workspaces.map((w) => w.name).join(', ');
    }
  }, [t, workspaces]);

  const selectMode = workspaces && workspaces.length > 2;

  const renderDropdown = () => {
    if (!display) return null;

    return (
      <OutsideClickHandler
        onOutsideClick={() => {
          setDisplay(false);
        }}
        useCapture={false}
      >
        <List alignMenu={alignMenu}>
          {listHeader && <ListHeader>{listHeader}</ListHeader>}
          {workspaces.map((w) => (
            <ListItem key={`w-list-${w.id}`}>
              <Label>{w.name}</Label>
            </ListItem>
          ))}
        </List>
      </OutsideClickHandler>
    );
  };

  const renderPortalDropdown = () => {
    return (
      <Portal
        container={
          portalContainerId ? document.getElementById(portalContainerId) : null
        }
      >
        <div
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
        >
          {renderDropdown()}
        </div>
      </Portal>
    );
  };

  return (
    <div style={{ width: '100%' }}>
      <Container>
        <div ref={setReferenceElement}>
          <Select
            light={light}
            hoverEffect={selectMode}
            onClick={() => {
              if (selectMode) {
                setDisplay(!display);
              }
            }}
          >
            <EllipsisToolTip>
              <Left
                data-tip={workspaces.length ? getWorkspaceValue() : ''}
                isPlaceholder={!workspaces.length}
                light={selectMode}
              >
                {workspaces.length ? getWorkspaceValue() : ''}
              </Left>
            </EllipsisToolTip>
            {selectMode && (
              <Right>
                <FaAngleDown />
              </Right>
            )}
          </Select>
        </div>
        {usePortal ? renderPortalDropdown() : renderDropdown()}
      </Container>
    </div>
  );
};
