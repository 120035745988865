import colors from 'constants/colors';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export type CellProps = {
  withPaddingLeft?: boolean;
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  position: relative;
  width: 100%;
`;

export const CellTitle = styled.span<CellProps>`
  display: block;
  font-size: 14px;
  line-height: 18px;
  ${(props) => (props.withPaddingLeft ? 'padding-left: 16px;' : '')}
  position: relative;

  svg {
    position: absolute;
    top: 1px;
    left: -4px;
  }
`;

export const CellSubtitle = styled.span<CellProps>`
  display: block;
  color: ${colors.gray500};
  font-size: 12px;
  line-height: 14px;
  ${(props) => (props.withPaddingLeft ? 'padding-left: 16px;' : '')}
`;

export const StyledLink = styled(Link)`
  background: ${colors.white};
  border-radius: 5px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  position: absolute;
  right: 0;
  text-decoration: none;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;

  ${Container}:hover & {
    visibility: visible;
  }

  &:hover {
    text-decoration: none;
  }
`;
