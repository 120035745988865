export enum FLAGS {
  ANALYTICS_IDLE_RATE_REPORT = 'analytics-idle-rate-v2',
  ANALYTICS_HOURLY_CONSUMPTION_REPORT = 'analytics-hourly-consumption-v2',
  ANALYTICS_CARBON_EMISSIONS_REPORT = 'analytics-carbon-emissions-v-2',
  ANALYTICS_NEW_EQUIPMENT_FAULTS_QUERY = 'analytics-new-equipment-faults-query',
  ANALYTICS_USAGE_HOURS_REPORT = 'analytics-usage-hours-v-2',
  REPORT_EXTENDED_PERIOD = 'report-extended-period',
  ANALYTICS_NEW_FLEET_DATA = 'new-fleet-data',

  ASSET_ALERTS_HISTORY = 'asset-alerts-history',
  ASSET_DETAILS_CONDENSED = 'asset-details-condensed',

  ASSET_FILES = 'assetinorganization-file-upload',
  SUGGEST_CATEGORIES = 'suggest-categories',

  ACTIVITY_ALERTS = 'activity-alerts',
  APP_SYNC_ONLY = 'app-sync-only',
  ASSET_TRIPS = 'asset-trips',
  CUSTOM_MAP_TILES = 'custom-map-tiles',
  SATELLITE_STADIA_MAPS = 'satellite-stadia-maps',
  DAILY_DATA = 'daily-data',
  DATA_HISTORY_SPAN = 'data-history-span',
  ENERGY_TYPE = 'energy-type',
  DESTINATIONS = 'destinations',
  FAULTS_REPORT = 'faults-report',
  FILE_ANALYTICS_EXPORT = 'file-analytics-export',
  FILE_INVENTORY_EXPORT = 'file-inventory-export',
  INVENTORY_ENGINE_CONDITION = 'inventory-engine-condition',
  NO_REPORT = 'no-report',
  PROFILE_I18N = 'profileI18n',
  REPORT_A_PROBLEM = 'report-a-problem',
  TYRE_V2 = 'tyre-v2',
  USAGE_REPORT = 'usage-report',
  WORKSPACE_CREATE = 'workspace-create',

  TMP_SHARING_FORM_HUBSPOT = 'tmp-sharing-form-hubspot',
  FLEET_SHARING = 'fleet-sharing',
}
