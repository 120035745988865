import styled from 'styled-components';

import colors from 'constants/colors';
import { DropdownMenuItem, DropdownMenuWrapper } from 'modules/common-ui';

export const ActionsWrapper = styled.div`
  overflow: visible;
  position: relative;
`;

export const IconWrapper = styled.div``;

export const ActionsListContainer = styled(DropdownMenuWrapper)`
  display: flex;
  flex-direction: column;
  margin-top: 2px;
  overflow: hidden;
  padding: 8px 0;
  position: absolute;
  right: 0;
  min-width: 246px;
  z-index: 1;
`;

export const ActionItem = styled(DropdownMenuItem)<{
  variant?: 'danger' | 'primary';
  disabled?: boolean;
}>`
  align-items: center;
  background: ${colors.white};
  color: ${(props) =>
    props.variant === 'danger' ? colors.red400 : colors.gray850};
  cursor: pointer;
  display: flex;
  font-size: 14px;
  line-height: 18px;
  padding: 3px 6px;
  transition: background 120ms ease-in 0s;

  ${(props) =>
    props.disabled &&
    `
    color: ${props.variant === 'danger' ? colors.red100 : colors.gray500};
    cursor: not-allowed;

    :hover {
      background: ${colors.white};
    }
  `};
`;

export const IconLeftWrapper = styled.div`
  display: flex;
  margin-right: 8px;
  width: 16px;
  height: 16px;
  justify-content: center;
  align-items: center;
`;

export const IconRightWrapper = styled.div`
  display: flex;
  margin-left: 8px;
  width: 16px;
  height: 16px;
  justify-content: center;
  align-items: center;
`;
