import { Fragment, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import OutsideClickHandler from 'react-outside-click-handler';
import { useMediaQuery } from 'react-responsive';

import { BREAKPOINT_DESKTOP_START } from 'constants/breakpoints';
import { Drawer } from 'modules/common-ui';
import debounce from 'utils/debounce';

import { RichButton } from './Button';

import { Checkbox } from 'modules/common-ui';
import { TextInput } from '../Form';
import {
  Action,
  ActionBar,
  ActionWrapper,
  Container,
  Label,
  List,
  ListHelperText,
  ListRow,
  Menu,
  NoResultsHelperText,
  Row,
  Separator,
} from './index.css';

export type RichMultiselectOption = {
  key: string;
  label: string;
  value: any;
};

export type RichMultiselectProps = {
  alignRight?: boolean;
  marginAlignRight?: number;
  label?: string;
  onUpdate: (value: any) => void;
  options: RichMultiselectOption[];
  placeholder?: string;
  searchPlaceholder?: string;
  values: [] | RichMultiselectOption[];
  customButtonRenderer?: (open: () => void) => React.ReactNode;
};

export const RichMultiselect = ({
  options,
  onUpdate,
  placeholder = '',
  searchPlaceholder = '',
  values,
  alignRight = false,
  label,
  customButtonRenderer,
  marginAlignRight = 8,
}: RichMultiselectProps) => {
  const [display, setDisplay] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [filteredOptions, setFilteredOptions] = useState(options);
  const isMobile = useMediaQuery({ maxWidth: BREAKPOINT_DESKTOP_START });

  const { t } = useTranslation('commonUi');

  // update internal state when options change (can happen if option are lazy loaded for instance)
  useEffect(() => {
    if (options && options.length) {
      setFilteredOptions(options);
    }
  }, [options]);

  const onChangeSearch = (value: string) => {
    setSearchText(value);
    filterOptions(value);
  };

  const filterOptions = debounce((value: string) => {
    if (!value || !value.length) {
      setFilteredOptions(options);

      return;
    }

    const filteredOptions = options.filter(
      (o) => o.label.toLowerCase().indexOf(value.toLowerCase()) !== -1,
    );

    setFilteredOptions(filteredOptions);
  }, 700);

  const checkIfChecked = (value: any) => {
    return values.findIndex((item) => item.value === value) !== -1;
  };

  const toggleCheckbox = (value: any) => {
    if (checkIfChecked(value)) {
      onUpdate(values.filter((item) => item.value !== value));

      return;
    }

    const option = options.find((item) => item.value === value);

    onUpdate([...values, option]);
  };

  const openContent = useCallback(() => {
    setDisplay(true);
  }, [setDisplay]);

  const renderButton = () => {
    if (customButtonRenderer) {
      return customButtonRenderer(openContent);
    }
    return (
      <RichButton
        values={values}
        placeholder={placeholder || t('richMultiselect.options.placeholder')}
        onClick={() => setDisplay(!display)}
        max={options.length}
      />
    );
  };

  const renderOptions = () => {
    if (isMobile) {
      return (
        <Drawer isOpen={display} onDone={() => setDisplay(false)} title={label}>
          <>
            <ActionWrapper>
              <TextInput
                placeholder={
                  searchPlaceholder || t('richMultiselect.search.placeholder')
                }
                value={searchText}
                onChange={(e) => onChangeSearch(e.target.value)}
                variant="secondary"
                fullWidth={isMobile}
                large
              />
              <ActionBar>
                <Action onClick={() => onUpdate(filteredOptions)}>
                  {t('richMultiselect.selectAll')}
                </Action>
                <Separator>·</Separator>
                <Action onClick={() => onUpdate([])}>
                  {t('richMultiselect.unselectAll')}
                </Action>
              </ActionBar>
            </ActionWrapper>
            <List>
              {filteredOptions.length > 0 && (
                <ListHelperText>
                  {t('richMultiselect.helperText.selectAnOption')}
                </ListHelperText>
              )}
              {searchText && searchText.length && !filteredOptions.length ? (
                <NoResultsHelperText>
                  {t('richMultiselect.helperText.noResults')}
                </NoResultsHelperText>
              ) : (
                <Fragment>
                  {filteredOptions.map((o) => (
                    <Row
                      noRadius
                      key={`row-${o.key}`}
                      onClick={() => {
                        toggleCheckbox(o.value);
                      }}
                    >
                      <ListRow>
                        <Checkbox
                          checked={checkIfChecked(o.value)}
                          onChange={() => {
                            toggleCheckbox(o.value);
                          }}
                        />
                        <Label>{o.label}</Label>
                      </ListRow>
                    </Row>
                  ))}
                </Fragment>
              )}
            </List>
          </>
        </Drawer>
      );
    }

    if (!display) {
      return null;
    }

    return (
      <Menu
        noPadding
        alignRight={alignRight}
        marginAlignRight={marginAlignRight}
      >
        <ActionWrapper>
          <TextInput
            placeholder={
              searchPlaceholder || t('richMultiselect.search.placeholder')
            }
            value={searchText}
            onChange={(e) => onChangeSearch(e.target.value)}
            variant="secondary"
            large
          />
          <ActionBar>
            <Action onClick={() => onUpdate(filteredOptions)}>
              {t('richMultiselect.selectAll')}
            </Action>
            <Separator>·</Separator>
            <Action onClick={() => onUpdate([])}>
              {t('richMultiselect.unselectAll')}
            </Action>
          </ActionBar>
        </ActionWrapper>
        <List>
          {filteredOptions.length > 0 && (
            <ListHelperText>
              {t('richMultiselect.helperText.selectAnOption')}
            </ListHelperText>
          )}
          {searchText && searchText.length && !filteredOptions.length ? (
            <NoResultsHelperText>
              {t('richMultiselect.helperText.noResults')}
            </NoResultsHelperText>
          ) : (
            <Fragment>
              {filteredOptions.map((o) => (
                <Row
                  noRadius
                  key={`row-${o.key}`}
                  onClick={() => {
                    toggleCheckbox(o.value);
                  }}
                >
                  <ListRow>
                    <Checkbox
                      checked={checkIfChecked(o.value)}
                      onChange={() => toggleCheckbox(o.value)}
                    />
                    <Label>{o.label}</Label>
                  </ListRow>
                </Row>
              ))}
            </Fragment>
          )}
        </List>
      </Menu>
    );
  };

  return (
    <Container>
      <OutsideClickHandler
        onOutsideClick={() => setDisplay(false)}
        useCapture={false}
      >
        {renderButton()}
        {renderOptions()}
      </OutsideClickHandler>
    </Container>
  );
};
